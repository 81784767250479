import React, { useState, useEffect } from 'react'
import { AimOutlined, CalendarOutlined, AlertOutlined, NodeIndexOutlined, SearchOutlined, MenuOutlined } from "@ant-design/icons"
import { Button, Select } from 'antd';
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next";
import classes from "./Navbar.module.css";
import { CloseOutlined, CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import { SidebarData } from './SidebarData'
import twitter from '../../img/twitter.png'
import facebook from '../../img/facebook.png'
import SubMenu from './SubMenu';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
} from 'react-router-dom';

const Navbar = () => {
  // const activeClass = (path: string, link: string) => {
  //   if (window.location === link) {
  //     return true;
  //   }
  //   return false;
  // };

  const [sidebar, setSidebar] = useState(false);
  const [submenu, setSubmenu] = useState(false);

  const showSidebar = (tab: any) => {
    console.log(tab.title);

    if (tab.toggle)
      setSidebar(!sidebar);

  }

  const [subNav, setSubNav] = useState(false)
  const showSubNav = () => setSubNav(!subNav)

  const [basicOpen, setBasicOpen] = useState(true);
  const [basicCollapse1, setBasicCollapse1] = useState(true);
  const [basicCollapse2, setBasicCollapse2] = useState(false);

  const [toggleMenu, setToggleMenu] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const { t } = useTranslation()

  const toggleNav = () => {
    setToggleMenu(!toggleMenu)
  }



  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener('resize', changeWidth)
    return () => {
      window.removeEventListener('resize', changeWidth)
    }
  }, [])

  return (
    <div>
      {(screenWidth > 1280) && (
        <ul className={[classes.list, classes.items].join(" ")}>
          {SidebarData.map((item: any, index: any) => {
            return (
              <NavLink key={item.id} to={item.path}
                style={({ isActive }) => ({
                  fontWeight: isActive ? 900 : 'normal',
                  // background: isActive ? '#7600dc' : '#f0f0f0',
                })}
                state={{ page: item.state }} onClick={toggleNav} >{t(item.title)} {item.iconClosed ? <CaretDownOutlined onClick={() => { setSubmenu(!submenu) }} /> : ''}</NavLink>

            )

          })}

        </ul>
      )}

      <button onClick={() => { setSidebar(!sidebar) }} className={classes.btn}><MenuOutlined /></button>


      <nav className={sidebar ? classes.navMenuActive : classes.navMenu}>
        <ul className={classes.navMenuItems} >
          <li className={classes.navbarToggle}>
            <Link to="#" className={classes.menuBars} >
              <CloseOutlined className={classes.closeIcon} onClick={() => { setSidebar(!sidebar) }} />
            </Link>
          </li>
          {SidebarData.map((item: any, index: any) => {
            return (
              <li key={index} className={classes.navText} onClick={() => { showSidebar(item) }}  >
                
                  <SubMenu item={item} key={index} onClick={() => { showSidebar(item) }} />
                


              </li>
              // {Submenu.map((item: any, index: any) => {})}
            )
          })}
          <div className={classes.socialSidebar}>
            <a href="#"><img src={twitter} className={classes.icon} alt="external-link" /></a>

            <a href="#"><img src={facebook} className={classes.icon} alt="external-link" /></a>
          </div>
        </ul>
      </nav>

    </div>
  )

}
export default Navbar
