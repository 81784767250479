import React, { useState, useEffect } from "react"
import { Row, Col, Select, Breadcrumb, Button, Checkbox, Form, Input } from 'antd'
import { EnvironmentOutlined, ClockCircleOutlined, SearchOutlined, HeartOutlined, ShakeOutlined } from '@ant-design/icons';
import { RightOutlined, PrinterOutlined } from '@ant-design/icons'
import { Link, useNavigate, Navigate } from "react-router-dom"
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"
import axios, { AxiosError } from "axios"
import { useIsAuthenticated, useSignIn } from 'react-auth-kit'
import externalBlue from './assets/external-blue.png'
import printBlue from './assets/print-blue.png'


import AuthService from "../../services/auth.service"



const { TextArea } = Input;



const onFinish = (values: any) => {
    console.log('Success:', values);
};

const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
};

const Contact = () => {

    const [error, setError] = useState("");
    // const signIn = useSignIn();
    const navigate = useNavigate();

    const onSubmit = async (values: any) => {
        console.log("Values: ", values);
        setError("");
    }

    const { t } = useTranslation()


    useEffect(() => {
        // const currentUser = AuthService.getCurrentUser();

        // if (currentUser) {
        //     console.log("LOGOUT");

        //     AuthService.logout()
        // }
    }, []);

    return (
        <>

            <div className={classes.container}>
                <Row justify="center">
                    <Col lg={24}>
                        <div className={classes.title}>
                            {t('ΕΠΙΚΟΙΝΩΝΙΑ')}
                        </div>
                    </Col>
                </Row>
            </div>
            <div className={classes.breadcrumbContainer}>
                <Row justify="center">
                    <Col xs={24} sm={24} md={15} lg={15}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <a href="/">{t('ΑΡΧΙΚΗ ΣΕΛΙΔΑ')}</a>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <p style={{ textTransform: "uppercase" }}>{t('ΕΠΙΚΟΙΝΩΝΙΑ')}</p>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </div>
            <div className={classes.mainContainer}>
                <div className={classes.login}>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <div className={classes.title}>
                                {t('Επικοινωνία')}
                            </div>
                        </Col>

                        <Form
                            name="basic"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            // style={{ maxWidth: 1160 }}
                            initialValues={{ remember: true }}
                            // onFinish={onFinish}
                            // onFinishFailed={onFinishFailed}
                            autoComplete="on"
                        >
                            <Row className={classes.dividedFields}>
                                <Col xs={24} sm={24} md={11} lg={11}>
                                    <Form.Item
                                        label="Όνομα"
                                        name="FirstName"
                                        rules={[{ required: true, message: 'Το όνομά σας είναι υποχρεωτικό!' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={11} lg={11}>
                                    <Form.Item
                                        label="Επώνυμο"
                                        name="Surname"
                                        rules={[{ required: true, message: 'Το επώνυμό σας είναι υποχρεωτικό!' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Col xs={24} sm={24} md={11} lg={11}>
                                <Form.Item
                                    label="Email"
                                    name="Email"
                                    rules={[{ required: true, message: 'Το email σας είναι υποχρεωτικό!' }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Form.Item
                                label="Λόγος επικοινωνίας"
                                name="ContactSubject"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item label="Μήνυμα">
                                <TextArea rows={6} />
                            </Form.Item>

                            <Form.Item wrapperCol={{ span: 24 }}>
                                <Button
                                    disabled
                                    type="primary" htmlType="submit"
                                    className={classes.PostBtn}
                                // onClick={Contact}
                                >
                                    {t('ΑΠΟΣΤΟΛΗ')} < RightOutlined />
                                </Button>
                            </Form.Item>

                            <Form.Item name="forgot" wrapperCol={{ span: 24 }}>
                                <a href="#"><u>{t('*Υποχρεωτικά πεδία')}</u></a>
                            </Form.Item>

                        </Form>


                    </Row>

                </div>
                <div className={classes.Info}>
                    <Row className={classes.dividedFields} style={{ marginTop: 34 }}>
                        <Col xs={24} sm={24} md={11} lg={11}>
                            <div className={classes.InfoCols}>
                                {t('Στοιχεία επικοινωνίας & Χρήσιμα τηλέφωνα')}
                            </div>
                            <div className={classes.InfoText}>

                                {t('Email: ')} <a href="mailto:dserron@serres.gr">dserron@serres.gr</a>
                                <br /><br />
                                {t('Τηλέφωνα: ')}<a href="tel:2321083600">23210 83600</a>, <a href="tel:2321350100">23213 50100</a>

                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={11} lg={11}>
                            <div className={classes.InfoCols}>
                                {t('Χρήσιμα αρχεία')}
                            </div>
                            {/* <div className={classes.InfoText}>
                                <strong>{t(`ΑΙΤΗΣΗ ΓΙΑ LOREM IPSUM`)}</strong>
                                <br />
                                {t(`fuga aut quia unde eos commodi sapiente`)}
                                <br />
                                <br />

                                <img src={printBlue} className={classes.icon} alt="external-link" />&nbsp;&nbsp;&nbsp; <img src={externalBlue} className={classes.icon} alt="external-link" />

                            </div>
                            <div className={classes.InfoText}>
                                <strong>{t(`ΑΙΤΗΣΗ ΓΙΑ LOREM IPSUM`)}</strong>
                                <br />
                                {t(`fuga aut quia unde eos commodi sapiente`)}
                                <br />
                                <br />

                                <img src={printBlue} className={classes.icon} alt="external-link" />&nbsp;&nbsp;&nbsp; <img src={externalBlue} className={classes.icon} alt="external-link" />

                            </div>
                            <div className={classes.InfoText}>
                                <strong>{t(`ΑΙΤΗΣΗ ΓΙΑ LOREM IPSUM`)}</strong>
                                <br />
                                {t(`fuga aut quia unde eos commodi sapiente`)}
                                <br />
                                <br />

                                <img src={printBlue} className={classes.icon} alt="external-link" />&nbsp;&nbsp;&nbsp; <img src={externalBlue} className={classes.icon} alt="external-link" />

                            </div>
                            <div className={classes.InfoText}>
                                <strong>{t(`ΑΙΤΗΣΗ ΓΙΑ LOREM IPSUM`)}</strong>
                                <br />
                                {t(`fuga aut quia unde eos commodi sapiente`)}
                                <br />
                                <br />

                                <img src={printBlue} className={classes.icon} alt="external-link" />&nbsp;&nbsp;&nbsp; <img src={externalBlue} className={classes.icon} alt="external-link" />
                            </div> */}
                        </Col>
                    </Row>
                </div>

            </div>
        </>
    );
};

export default Contact