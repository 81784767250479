import React from 'react'
import { Row, Col, Button, Pagination } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import classes from "./css/Info.module.css"
import { useTranslation } from "react-i18next"


const Info: React.FC = () => {

    const { t } = useTranslation()

    return (
        <>
            <div className={classes.container}>
                <div className={classes.info} style={{ marginTop: 34, marginBottom: 43 }}>
                    <div className={classes.titleSection}>
                        {t('ΠΛΗΡΟΦΟΡΙΕΣ')}
                    </div>
                    <div className={classes.textBold}>
                        {t(`ΑΝΑΠΤΥΞΗ ΚΑΙ ΛΕΙΤΟΥΡΓΙΑ ΔΙΚΤΥΟΥ ΕΘΕΛΟΝΤΙΣΜΟΥ ΥΠΟΣΤΗΡΙΞΗΣ ΤΩΝ ΕΥΠΑΘΩΝ ΟΜΑΔΩΝ ΠΛΗΘΥΣΜΟΥ ΣΤΟΝ ΔΗΜΟ ΣΕΡΡΩΝ`)}
                    </div>
                    <div className={classes.text}>
                        {t(`Αντικείμενο του έργου είναι η ανάπτυξη και ενίσχυση του δικτύου εθελοντισμού στον Δήμο Σερρών, έτσι ώστε να αναπτυχθούν δράσεις κοινωνικής αλληλεγγύης και πρόνοιας που θα απευθύνονται σε ευπαθείς ομάδες του πληθυσμού, με στόχο την ενίσχυση της κοινωνικής συνοχής και τον περιορισμό των διακρίσεων που υφίστανται οι ομάδες αυτές στην καθημερινή τους διαβίωση.`)}

                        <br /><br />
                        {t(`Στο πλαίσιο αυτό θα υπάρχει επίσης μέριμνα για την προαγωγή και την ανάπτυξη της σωματικής, πνευματικής, ηθικής και αισθητικής αγωγής των παιδιών, των νέων και όλων των δημοτών για την ενθάρρυνση και ενεργοποίησή τους στην ανάπτυξη των εθελοντικών δράσεων.`)}
                        <br /><br />

                        {t(`Σκοπός του παρόντος έργου είναι η ανάδειξη και η αξιοποίηση του συνόλου του τοπικού δυναμικού που δραστηριοποιείται στο χώρο του εθελοντισμού στον Δήμο Σερρών.
Βασικό στοιχείο προς την κατεύθυνση αυτή είναι η δημιουργία μίας βάσης πληροφοριών και η καταγραφή όλων των συλλόγων οργανώσεων και επιχειρήσεων που δραστηριοποιούνται στον τομέα του εθελοντισμού.
Ταυτόχρονα θα γίνει καταγραφή των δυνατοτήτων να συνεισφέρουν με τις υπηρεσίες τους όσο και των ικανοτήτων, γνώσεων, δεξιοτήτων ή εμπειριών κάθε δημότη εθελοντή για προσφορά αντίστοιχου εθελοντικού έργου.`)}
                        <br /><br />

                        {t(`Σημαντικές είναι επίσης οι προβλεπόμενες ενέργειες ευαισθητοποίησης, ενημέρωσης και πληροφόρησης, τόσο αυτών που δραστηριοποιούνται ή έχουν την επιθυμία να συμμετέχουν σε δράσεις εθελοντισμού, όσο και των αντίστοιχων πληθυσμιακών ομάδων στις οποίες απευθύνονται οι δράσεις εθελοντισμού.`)}
                        <br /><br />
                    </div>
                </div>

            </div>
        </>
    )
}

export default Info