import React, { useState, useEffect, } from "react"
import { Row, Col, Select, Breadcrumb, Button, Image } from 'antd'
import { EnvironmentOutlined, ClockCircleOutlined, SearchOutlined, HeartOutlined, ShakeOutlined } from '@ant-design/icons';
import { RightOutlined } from '@ant-design/icons'
import { Link, useParams, } from "react-router-dom"
import classes from './styles.module.css'
import { useTranslation } from "react-i18next";
import parse from 'html-react-parser';
import moment from "moment";

import Posts from "../Home/components/Posts"
import { log } from "console";

export type NewsPost = {
    id: string;
    title: string;
    // image: image;
    created: string;
    url: string;
}

// export type image = {
//     description: string;
//     url: string;
// }

export type PostTypes = NewsPost[];

const ViewNewsPost = () => {

    const { t } = useTranslation()

    const params = useParams();


    const [newspost, setNewsPost] = useState<{ id: '', title: "", created: Date, image: { url: "" }, category: { title: "" }, description: '', author: '' }>();

    const [timeStamp, SetTimeStamp] = useState<Date>();
    const [dateTime, SetDateTime] = useState<string>("");

    let dateFormatted = "";

    const fetchNewsData = () => {
        // console.log(params);
        fetch(`${process.env.REACT_APP_SERVER}/api/el/news/` + params.id, {
            method: 'GET',
            headers: { "API-KEY": "MXtthZ2ewCmrWQzUj9VPVyOyqqqv6VLe" }
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                setNewsPost(data)

                // console.log(data.created);


                var timestamp = data.created;
                require('moment/locale/el');
                dateFormatted = moment.unix(timestamp).format('LL');
                // console.log(dateFormatted);
                SetDateTime(dateFormatted)


            })
    }

    // const formatTime = () => {
    //     return Intl.DateTimeFormat('el-GR', { dateStyle: 'medium' }).format(timeStamp);
    // }

    useEffect(() => {
        fetchNewsData();
    }, [])

    return (
        <>

            <div className={classes.container}>
                <Row justify="center">
                    <Col lg={24}>
                        <div className={classes.title}>
                            {t('ΝΕΑ')}
                        </div>
                    </Col>
                </Row>
            </div>

            <div className={classes.breadcrumbContainer}>
                <Row justify="center">
                    <Col xs={24} sm={24} md={15} lg={15}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <a href="/">{t('ΑΡΧΙΚΗ ΣΕΛΙΔΑ')}</a>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <a href="/news">{t('ΝΕΑ')}</a>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <p lang="el" style={{ textTransform: "uppercase" }}>{newspost?.title}</p>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </div>
            <div className={classes.mainContainer}>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <div className={classes.Category}>
                            <p lang='el' style={{ textTransform: "uppercase" }}>{newspost?.category.title}</p>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <div className="">
                            <div className={classes.ActionTitle}>{newspost?.title}
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>

                        <div className={classes.Date}>
                            {/* {t('12 ΣΕΠΤΕΜΒΡΙΟΥ 2022')} */}
                            <p lang='el' style={{ textTransform: "uppercase" }}>{dateTime}</p>
                            {/* {dateTime} */}
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={10}>
                        <div className={classes.Details}>
                            {newspost?.author}
                        </div>
                    </Col>
                </Row>
                <img className={classes.ImagePost} src={newspost?.image.url} alt="Image" />
            </div>

            <div className={classes.NewsContainer} style={{ marginBottom: 50 }}>

                <Row>
                    <Col xs={24} sm={24} md={24} lg={14}>
                        <div className="">
                            <div className={classes.Description}>
                                {/* {t('Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur')} */}
                                {parse(`${newspost?.description}`)}
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={10}>
                        <div className={classes.MediaTitle}>
                            {t('MEDIA')}
                        </div>
                        <div className={classes.MediaWrap}>
                            <Row>
                                <Image.PreviewGroup>
                                    <Col xs={24} sm={24} md={8} lg={24}>
                                        <Image className={classes.MediaImg} src={newspost?.image.url} alt="Image" />
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={24}>
                                        <Image className={classes.MediaImg} src={newspost?.image.url} alt="Image" />
                                    </Col>
                                    <Col xs={24} sm={24} md={8} lg={24}>
                                        <Image className={classes.MediaImg} src={newspost?.image.url} alt="Image" />
                                    </Col>
                                </Image.PreviewGroup>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>

        </>
    );
};

export default ViewNewsPost