import React, { useState, useEffect, useRef } from "react"
import { Row, Col, Select, Breadcrumb, Button, Checkbox, Form, Input, Switch, Table } from 'antd'
import { EnvironmentOutlined, ClockCircleOutlined, SearchOutlined, HeartOutlined, ShakeOutlined } from '@ant-design/icons';
import { RightOutlined, LockOutlined, EditOutlined } from '@ant-design/icons'
import { Link, useNavigate, Navigate } from "react-router-dom"
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"
import { InfoInput } from "./InfoInput/InfoInput";
import printBlue from '../../pages/Contact/assets/print-blue.png'
import externalBlue from '../../pages/Contact/assets/external-blue.png'
import AuthService from "../../services/auth.service"
import moment from "moment";

let passed: any = [];
let upcoming: any = [];

const VolunteerArea = () => {

    const { t } = useTranslation();
    let userData;

    if (window.localStorage.getItem("userData")) {
        userData = JSON.parse(window.localStorage.getItem("userData")!);
        console.log("UserDATA:", userData);
    }

    let [user, setUser] = useState({ name: "", last_name: "", type: "", email: "", password: "", telephone: "", address: "", availability: false });

    console.log(user);

    const editProfile = async (email: string, password: string, telephone: string, address: string, availability: boolean) => {
        const response = await fetch(`${process.env.REACT_APP_SERVER}/api/users/edit`, {
            method: 'POST',
            headers: new Headers({
                'Accept': 'application/json',
                'Origin': '',
                "Authorization": `Bearer ${window.localStorage.getItem("access_token")}`,
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
                'grant_type': 'client_credentials',
                "email": email,
                "password": password,
                "telephone": telephone,
                "address": address,
                "availability": availability
            })
        })

        const data = await response.text();
        console.log(data);

        return data;
    }

    // const onSubmit = async (values: any) => {
    //     console.log("Values: ", values);
    //     setError("");
    // }

    function checkExceedLimit(props: any) {
        let date1 = moment(props, moment.defaultFormat)
        if (moment(date1).isBefore()) {
            // console.log(`${props} has passed!`);
            return false;
        }
        else {
            // console.log(`${props} not passed!`);
            return true;
        }
    }

    const isNull = (x: any) => {
        if (x == null) {
            return true;
        }
        else {
            return false;
        }
    }



    const add = (arr: [{ title: string }], obj: { title: string }) => {
        const { length } = arr;
        const id = length + 1;
        const found = arr.some(el => el.title === obj.title);
        if (!found) arr.push(obj);
        return arr;
    }



    // AuthService.getUserProfile()

    // setData(AuthService.getActions())
    console.log('GET USER ACTIONS FOR VOLUNTEERAREA');
    const data2 = AuthService.getActions()


    console.log('GET USER FROM LOCALSTORAGE');


    data2.sort((a: any, b: any) => (AuthService.checkNewest(a.endDate, b.endDate)) ? 1 : -1)

    data2.map((row: any) => {
        if (checkExceedLimit(row.startDate)) {
            console.log("upcoms");

            add(upcoming, row)
            // upcoming.push(row)
        }
        else
            add(passed, row)
    })
    // list.sort((a, b) => (a.color > b.color) ? 1 : -1)

    const columns = [
        {
            title: 'Α/Α',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'ΟΝΟΜΑΣΙΑ ΔΡΑΣΗΣ',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'ΠΕΡΙΟΔΟΣ ΔΡΑΣΗΣ',
            dataIndex: 'period',
            key: 'period',

        },
        {
            title: <img src={printBlue} className={classes.tableIcon} alt="external-link" />,
            dataIndex: 'datePeriod',
            key: 'date-period',
        },
        {
            title: <img src={externalBlue} className={classes.tableIcon} alt="external-link" />,
            dataIndex: 'url',
            key: 'url',
        },
    ];

    const onFinish = (values: any) => {
        // console.log('Success:', values);

    };
    const onFinishFailed = (errorInfo: any) => {
        // console.log('Failed:', errorInfo);
    };

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [telephone, setTelephone] = useState("");
    const [address, setAddress] = useState("");
    const [availability, setAvailability] = useState<boolean>(userData?.availability);

    const [clicked, setClicked] = useState(false);

    const onChange = (checked: boolean) => {

        console.log(`switch to ${checked}`);
        console.log("availability :", checked);


        setAvailability(checked);
    };

    const sendForm = () => {
        console.log();

        console.log("SEND FORM");

        editProfile(email, password, telephone, address, availability)
            .then(async () => {
                console.log(1);

                AuthService.login(window.location.href)
                console.log(2);

                console.log(3);
                await setUser(JSON.parse(window.localStorage.getItem("userData")!))
                console.log(4);

            })
            .then(async () => {
                console.log(clicked);


                await setClicked(!clicked)


                console.log(clicked);

                await setUser(JSON.parse(window.localStorage.getItem("userData")!))
            })



        // .then((data) => {
        //     console.log(data);
        //     AuthService.login(window.location.href)
        //         .then(async () => {
        //             await AuthService.getUserProfile()
        //                 .then(() => {
        //                     setUser(JSON.parse(window.localStorage.getItem("userData")!))

        //                 })
        //                 .finally(() => {
        //                     setClicked(!clicked)
        //                     console.log(4);
        //                 })

        //         })

        // })
        console.log(availability);


    }
    const disconnect = () => {
        // window.localStorage.clear();
        // AuthService.logout()
        window.localStorage.clear();
        (window as Window).location = `${process.env.REACT_APP_SERVER}/user/logout`
    }

    useEffect(() => {
console.log(window.localStorage);

        setUser(JSON.parse(window.localStorage.getItem("userData")!))
        console.log(clicked);
        // console.log(window.localStorage.getItem("userData"))
        console.log(window.localStorage.getItem("userData"));
    }, [clicked]);

    return (
        <>
            <div className={classes.container}>
                <Row justify="center">
                    <Col lg={24}>
                        <div className={classes.title}>
                            {t('ΠΕΡΙΟΧΗ ΕΘΕΛΟΝΤΩΝ')}
                        </div>
                    </Col>
                </Row>
            </div>
            <div className={classes.breadcrumbContainer}>
                <Row justify="center">
                    <Col xs={24} sm={24} md={15} lg={15}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <a href="/">{t('ΑΡΧΙΚΗ ΣΕΛΙΔΑ')}</a>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <p style={{ textTransform: "uppercase" }}>{t('ΚΑΡΤΕΛΑ ΕΘΕΛΟΝΤΗ')}</p>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </div>
            <div className={classes.mainContainer}>
                <div className={classes.login}>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <div className={classes.title}>
                                {t('Καρτέλα Εθελοντή')}
                            </div>
                        </Col>
                        {(!isNull(user)) ?
                            <Form
                                name="basic"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                // style={{ maxWidth: 1160 }}
                                initialValues={{ remember: true }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="on"
                            >
                                <Row className={classes.dividedFields}>
                                    <Col xs={24} sm={24} md={11} lg={11}>
                                        <div className={classes.label}>
                                            {t('Όνομα')}
                                        </div>
                                        <div className={classes.Data}>
                                            {t(user.name)} <LockOutlined />
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={24} md={11} lg={11}>
                                        <div className={classes.label}>
                                            {t('Επώνυμο')}
                                        </div>
                                        <div className={classes.Data}>
                                            {t(user.last_name)} <LockOutlined />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className={classes.dividedFields}>
                                    <Col xs={24} sm={24} md={11} lg={11}>
                                        <div className={classes.label}>
                                            {t('Email')}
                                        </div>
                                        <div className={classes.Data}>
                                            <InfoInput setVal={setEmail} defaultValue={t(userData?.name)!} placeholder={t(user.email)}></InfoInput>
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={24} md={11} lg={11}>
                                        <div className={classes.label}>
                                            {t('Τύπος Χρήστη')}
                                        </div>
                                        <div className={classes.Data}>
                                            {t(user.type)} <LockOutlined />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className={classes.dividedFields}>
                                    <Col xs={24} sm={24} md={11} lg={11}>
                                        <div className={classes.label}>
                                            {t('Τηλέφωνο επικοινωνίας')}
                                        </div>
                                        <div className={classes.Data}>
                                            <InfoInput setVal={setTelephone} defaultValue={t(user.telephone)} placeholder={t(user.telephone)}></InfoInput>
                                        </div>
                                    </Col>
                                </Row>
                                <div className={classes.label}>
                                    {t('Διεύθυνση')}
                                </div>
                                <div className={classes.Data}>
                                    <InfoInput setVal={setAddress} defaultValue={t(user.address)} placeholder={t(user.address)}></InfoInput>
                                </div>
                                <Row className={classes.dividedFields} style={{ marginBottom: 69 }}>
                                    <Col xs={24} sm={24} md={11} lg={11}>
                                        <Form.Item
                                            name="ChangePassword"
                                            label="Αλλαγή Κωδικού Πρόσβασης"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'Εισαγάγετε τον κωδικό πρόσβασής σας!',
                                                },
                                            ]}
                                            hasFeedback
                                        >
                                            <Input.Password />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={11} lg={11}>
                                        <Form.Item
                                            name="ConfirmeChangePassword"
                                            label="Επιβεβαίωση Αλλαγής Κωδικού Πρόσβασης"
                                            dependencies={['password']}
                                            hasFeedback
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'Επιβεβαιώστε τον κωδικό πρόσβασής σας!',
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue('ChangePassword') === value) {
                                                            setPassword(getFieldValue('ConfirmeChangePassword'));
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error('Οι δύο κωδικοί πρόσβασης που εισαγάγατε δεν ταιριάζουν!'));
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input.Password />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row justify="center" style={{ marginTop: 57 }}>
                                    <div className={classes.Available}>
                                        {t('Διαθέσιμος')}&nbsp;&nbsp;
                                    </div>&nbsp;
                                    <h1>{availability.valueOf()}</h1>
                                    {availability.valueOf() ? <>{user?.availability.valueOf()}<Switch defaultChecked onChange={onChange} /></> : <>{user?.availability.valueOf()}<Switch onChange={onChange} /></>}

                                </Row>
                                <Form.Item wrapperCol={{ span: 24 }}>
                                    <Button
                                        type="primary" htmlType="submit"
                                        className={classes.PostBtn}
                                        onClick={() => sendForm()}
                                    >
                                        {t('ΕΝΗΜΕΡΩΣΗ ΕΘΕΛΟΝΤΗ')} < RightOutlined />
                                    </Button>
                                </Form.Item>
                                <Form.Item name="forgot" wrapperCol={{ span: 24 }}>
                                    <p className={classes.text}><u>{t('*Υποχρεωτικά πεδία')}</u></p>
                                </Form.Item>
                            </Form> : ""
                        }
                    </Row>
                </div>
                <div className={classes.Info}>
                    <Row className={classes.dividedFields} style={{ marginTop: 34 }}>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <div className={classes.InfoCols}>
                                {t('Ιστορικό συμμετοχών')}
                            </div>
                            <Table className={classes.table} dataSource={passed} columns={columns} />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <div className={classes.InfoCols}>
                                {t('Επερχόμενες συμμετοχές')}
                            </div>
                            <Table className={classes.table} dataSource={upcoming} columns={columns} />
                        </Col>
                    </Row>
                    <br /><br /><br />
                    <Button
                        type="primary" htmlType="submit"
                        className={classes.PostBtn}
                        onClick={() => disconnect()}
                    >
                        {t('ΑΠΟΣΥΝΔΕΣΗ')} < RightOutlined />
                    </Button>
                </div>
            </div >
        </>
    );
};

export default VolunteerArea