import React, { useState } from "react"
import { Row, Col, Breadcrumb, Button, Form, Input, Select, Space } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import { useNavigate } from "react-router-dom"
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"
import axios, { AxiosError } from "axios"
import Axios from "axios"
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
// import { Option } from "antd/es/mentions"
const { Option } = Select;


const Register = () => {

    const [selectedValue1, setSelectedValue1] = useState('volunteer');

    const handleSelect1Change = (value: any) => {
        setSelectedValue1(value);
    };

    const { t } = useTranslation()
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const [errorMessage, setErrorMessage] = React.useState<boolean>(false);

    const [firstnameReg, setFirstnameReg] = useState("");
    const [surnameReg, setSurnameReg] = useState("");
    const [addressReg, setAddressReg] = useState("");
    const [emailReg, setEmailReg] = useState("");
    const [typeReg, setTypeReg] = useState("");
    const [phoneReg, setPhoneReg] = useState("");

    const [passReg, setPassReg] = useState("");

    const onFinish = (values: any) => {

        setFirstnameReg(values.FirstName);
        setSurnameReg(values.Surname);
        setAddressReg(values.Address);
        setEmailReg(values.Email);
        setPhoneReg(values.Phone);
        setPassReg(values.confirm);
        let data = {};
        if (selectedValue1 === 'volunteer') {
            data = {
                type: 'volunteer',
                email: values.Email,
                password: values.confirm,
                name: values.FirstName,
                last_name: values.Surname,
                telephone: values.Phone,
                address: values.Address,
            };
        }
        else {
            data = {
                type: 'client',
                email: values.Email,
                password: values.confirm,
                representative_name: values.FirstName,
                representative_surname: values.Surname,
                representative_telephone: values.Phone,
                address: values.Address,
            };
        }
        console.log(data);

        fetch(`${process.env.REACT_APP_SERVER}/api/users/register`, {
            method: 'POST',
            headers: { "API-KEY": "MXtthZ2ewCmrWQzUj9VPVyOyqqqv6VLe" },
            body: JSON.stringify(data)
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.error == "Email already in use.") {
                    setErrorMessage(true)
                }
                else {
                    setErrorMessage(false)
                    navigate("/volunteer-area")
                }
            })
            .catch((error) => {
                if (error.response.status)
                    alert('302')
                console.log(error)
            });
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    Axios.defaults.withCredentials = true;

    const handleChange = (value: string) => {
        console.log(`selected ${value}`);
        form.setFieldsValue({ userName: value });
    };

    const register = () => {
        let data = {
            email: emailReg,
            password: passReg,
            type: typeReg,
            name: firstnameReg,
            last_name: surnameReg,
            telephone: phoneReg,
            address: addressReg,
        };

        fetch(`${process.env.REACT_APP_SERVER}/api/users/register`, {
            method: 'POST',
            headers: { "API-KEY": "MXtthZ2ewCmrWQzUj9VPVyOyqqqv6VLe" },
            body: JSON.stringify(data)
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                console.log(data);
                navigate("/volunteer-area")
            })
            .catch((error) => {
                console.log(error)
            });
    };

    return (
        <>

            <div className={classes.container}>
                <Row justify="center">
                    <Col lg={24}>
                        <div className={classes.title}>
                            {t('ΕΓΓΡΑΦΗ')}
                        </div>
                    </Col>
                </Row>
            </div>
            <div className={classes.breadcrumbContainer}>
                <Row justify="center">
                    <Col xs={24} sm={24} md={15} lg={15}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <a href="/">{t('ΑΡΧΙΚΗ ΣΕΛΙΔΑ')}</a>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <a href="/volunteer-area">{t('ΠΕΡΙΟΧΗ ΕΘΕΛΟΝΤΩΝ')}</a>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <p style={{ textTransform: "uppercase" }}>{t('ΕΓΓΡΑΦΗ')}</p>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </div>
            <div className={classes.mainContainer}>
                <div className={classes.login}>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <div className={classes.title}>
                                {t('Εγγραφή Χρήστη')}
                            </div>
                        </Col>




                        <Form
                            form={form}
                            name="basic"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            // style={{ maxWidth: 1160 }}
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="on"
                        >

                            <Form.Item label="Τύπος Χρήστη" style={{ display: 'flex', justifyContent: 'center' }}>
                                <Select value={selectedValue1} onChange={handleSelect1Change}>
                                    <Option value="volunteer">Εθελοντής</Option>
                                    <Option value="client">Φορέας</Option>
                                </Select>
                            </Form.Item>

                            {selectedValue1 === 'client' && (
                                <>
                                    <Row className={classes.dividedFields}>
                                        <Col xs={24} sm={24} md={11} lg={11}>
                                            <Form.Item
                                                name={`Όνομα νόμιμου εκπροσώπου`}
                                                label={`Όνομα νόμιμου εκπροσώπου`}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "Το πεδίο είναι υποχρεωτικό!",
                                                    }
                                                ]}
                                                hasFeedback
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={11} lg={11}>
                                            <Form.Item
                                                name={`Επώνυμο νόμιμου εκπροσώπου`}
                                                label={`Επώνυμο νόμιμου εκπροσώπου`}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "Το πεδίο είναι υποχρεωτικό!",
                                                    }
                                                ]}
                                                hasFeedback
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row className={classes.dividedFields}>
                                        <Col xs={24} sm={24} md={11} lg={11}>
                                            <Form.Item
                                                name={`Τηλέφωνο νόμιμου εκπροσώπου`}
                                                label={`Τηλέφωνο νόμιμου εκπροσώπου`}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "Το πεδίο είναι υποχρεωτικό!",
                                                    }
                                                ]}
                                                hasFeedback
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={11} lg={11}>
                                            <Form.Item
                                                label="Διεύθυνση"
                                                name="Address"
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </>
                            )}

                            {selectedValue1 === 'volunteer' && (
                                <>
                                    <Row className={classes.dividedFields}>
                                        <Col xs={24} sm={24} md={11} lg={11}>
                                            <Form.Item
                                                label="Όνομα"
                                                name="FirstName"
                                                rules={[{ required: true, message: 'Το πεδίο είναι υποχρεωτικό!' }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={11} lg={11}>
                                            <Form.Item
                                                label="Επώνυμο"
                                                name="Surname"
                                                rules={[{ required: true, message: 'Το πεδίο είναι υποχρεωτικό!' }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row className={classes.dividedFields}>
                                        <Col xs={24} sm={24} md={11} lg={11}>
                                            <Form.Item
                                                label="Τηλέφωνο επικοινωνίας"
                                                name="Phone"
                                                rules={[{ required: true, message: 'Το πεδίο είναι υποχρεωτικό!' }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={11} lg={11}>
                                            <Form.Item
                                                label="Διεύθυνση"
                                                name="Address"
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </>
                            )}



                            <Row className={classes.dividedFields}>
                                <Col xs={24} sm={24} md={11} lg={11}>
                                    <Form.Item
                                        name="Email"
                                        label="Email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Το πεδίο είναι υποχρεωτικό!',
                                            },
                                        ]}
                                        hasFeedback
                                    >
                                        <Input />

                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={11} lg={11}>
                                    <Form.Item
                                        name="ConfirmEmail"
                                        label="Επιβεβαίωση Email"
                                        dependencies={['email']}
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Επιβεβαιώστε το email σας!',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('Email') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('Τα email δεν ταιριάζουν!'));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>




                            <Row className={classes.dividedFields}>
                                <Col xs={24} sm={24} md={11} lg={11}>
                                    <Form.Item
                                        name="password"
                                        label="Κωδικός"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Το πεδίο είναι υποχρεωτικό!',
                                            },
                                        ]}
                                        hasFeedback
                                    >
                                        <Input.Password />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={11} lg={11}>
                                    <Form.Item
                                        name="confirm"
                                        label="Επιβεβαίωση Κωδικού Πρόσβασης*"
                                        dependencies={['password']}
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Επιβεβαιώστε τον κωδικό πρόσβασής σας!',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('Οι κωδικοί πρόσβασης δεν ταιριάζουν!'));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item wrapperCol={{ span: 24 }}>
                                <Button
                                    type="primary" htmlType="submit"
                                    className={classes.PostBtn}
                                >
                                    {t('ΕΓΓΡΑΦΗ ΣΤΟ ΣΥΣΤΗΜΑ')} < RightOutlined />
                                </Button>
                                {errorMessage ? <span style={{ color: 'red', display: 'flex', justifyContent: 'center' }}>* Το email είναι ήδη σε χρήση!</span> : ""}
                            </Form.Item>

                            <div style={{ padding: '0 0 24px 0' }}>
                                <a href="#"><u>{t('*Υποχρεωτικά πεδία')}</u></a>
                            </div>
                        </Form>
                    </Row>
                </div>
            </div>
        </>
    );
};

export default Register