import React, { useState, useEffect } from "react"
import { Row, Col, Select, Breadcrumb, Button, Checkbox, Form, Input } from 'antd'
import { EnvironmentOutlined, ClockCircleOutlined, SearchOutlined, HeartOutlined, ShakeOutlined } from '@ant-design/icons';
import { RightOutlined, PrinterOutlined } from '@ant-design/icons'
import { Link, useNavigate, Navigate } from "react-router-dom"
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"
import axios, { AxiosError } from "axios"
import { useIsAuthenticated, useSignIn } from 'react-auth-kit'
const { TextArea } = Input;


const onFinish = (values: any) => {
    console.log('Success:', values);
};

const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
};

const GDPR = () => {
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const onSubmit = async (values: any) => {
        console.log("Values: ", values);
        setError("");
    }

    const { t } = useTranslation()

    const year = new Date();

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER}/api/el/pages/gdpr`, {
            method: 'GET',
            headers: {
                "API-KEY": "MXtthZ2ewCmrWQzUj9VPVyOyqqqv6VLe",
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        })
            .then(response => {
                return response.text()
            })
            .then(data => {
                console.log(data);
                
            })
    }, []);


    return (
        <>

            <div className={classes.container}>
                <Row justify="center">
                    <Col lg={24}>
                        <div className={classes.title}>
                            {t('Πολιτική Απορρήτου')}
                        </div>
                    </Col>
                </Row>
            </div>
            <div className={classes.breadcrumbContainer}>
                <Row justify="center">
                    <Col xs={24} sm={24} md={15} lg={15}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <a href="/">{t('ΚΕΝΤΡΙΚΗ ΣΕΛΙΔΑ')}</a>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <p style={{ textTransform: "uppercase" }}>{t('ΠΟΛΙΤΙΚΗ ΑΠΟΡΡΗΤΟΥ')}</p>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </div>
            <div className={classes.mainContainer}>
                <div className={classes.login}>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <div className={classes.title}>
                                {t('Πολιτική Απορρήτου')}
                            </div>
                        </Col>

                        


                    </Row>

                </div>
                <div className={classes.Info}>
                    <Row className={classes.dividedFields} style={{ marginTop: 34 }}>
                        <Col xs={24} sm={24} md={11} lg={11}>
                            <div className={classes.InfoCols}>
                                {t('Στοιχεία επικοινωνίας & Χρήσιμα τηλέφωνα')}
                            </div>
                            <div className={classes.InfoText}>

                                {t(`unde eos commodi

Ab vitae adipisci eos 
quia inventore quo sapiente 

commodi: 89278732387283
voluptatem: 84587343002

fuga aut quia unde eos commodi sapiente
09:00 - 15:00`)}

                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={11} lg={11}>
                            <div className={classes.InfoCols}>
                                {t('Χρήσιμα αρχεία')}
                            </div>
                            <div className={classes.InfoText}>
                                <strong>{t(`ΑΙΤΗΣΗ ΓΙΑ LOREM IPSUM`)}</strong>
                                <br />
                                {t(`fuga aut quia unde eos commodi sapiente`)}
                                <br />
                                <br />

                                <PrinterOutlined />&nbsp;&nbsp;&nbsp;<PrinterOutlined />
                            </div>
                            <div className={classes.InfoText}>
                                <strong>{t(`ΑΙΤΗΣΗ ΓΙΑ LOREM IPSUM`)}</strong>
                                <br />
                                {t(`fuga aut quia unde eos commodi sapiente`)}
                                <br />
                                <br />

                                <PrinterOutlined />&nbsp;&nbsp;&nbsp;<PrinterOutlined />
                            </div>
                            <div className={classes.InfoText}>
                                <strong>{t(`ΑΙΤΗΣΗ ΓΙΑ LOREM IPSUM`)}</strong>
                                <br />
                                {t(`fuga aut quia unde eos commodi sapiente`)}
                                <br />
                                <br />

                                <PrinterOutlined />&nbsp;&nbsp;&nbsp;<PrinterOutlined />
                            </div>
                            <div className={classes.InfoText}>
                                <strong>{t(`ΑΙΤΗΣΗ ΓΙΑ LOREM IPSUM`)}</strong>
                                <br />
                                {t(`fuga aut quia unde eos commodi sapiente`)}
                                <br />
                                <br />

                                <PrinterOutlined />&nbsp;&nbsp;&nbsp;<PrinterOutlined />
                            </div>
                        </Col>
                    </Row>
                </div>

            </div>
        </>
    );
};

export default GDPR