import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Pagination, Descriptions } from 'antd'
import type { PaginationProps } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import classes from "./css/Posts.module.css"
import { useTranslation } from "react-i18next"
import { useNavigate, Link } from "react-router-dom"
import parse from 'html-react-parser';
import moment from "moment";
import Modal from '../../../components/Modal/Modal'
import authService from '../../../services/auth.service'

interface postType {
    title: string;
    handler: Function
}

const Posts: React.FC<postType> = (props: postType) => {
    const [counter, setCounter] = useState(0)

    let url = "";
    let parent = "";
    let page = props.title;
    const newsUrl = `${process.env.REACT_APP_SERVER}/api/el/news`;
    const actionsUrl = `${process.env.REACT_APP_SERVER}/api/el/volunteering-actions`;

    if (props.title == "News") {
        url = newsUrl;
        parent = "news";
        page = "post";
    }
    else {
        url = actionsUrl;
        parent = "actions";
        page = "action";
    }


    const { t } = useTranslation()

    const navigate = useNavigate();

    let [data, setData] = React.useState<any[]>([])


    const [current, setCurrent] = useState(0);

    const onChange: PaginationProps['onChange'] = (page) => {
        // console.log(page - 1);
        setCurrent(page - 1);
    };

    const fetchData = async () => {
        await fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: { "API-KEY": "MXtthZ2ewCmrWQzUj9VPVyOyqqqv6VLe" }
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (props.title == "News") {
                    console.log(data.news);

                    const dataa = data.news.map((row: any) => {
                        const create = authService.formatTimeQuick(row.created);
                        row.createDate = create;
                        return row
                    })
                    dataa.sort((a: any, b: any) => (authService.checkNewest(a.createDate, b.createDate)) ? 1 : -1)

                    setData(dataa);
                }
                else {
                    const dataa = data.volunteering_actions.map((row: any) => {
                        const start = authService.formatTimeQuick(row.start_date);
                        row.startDate = start;
                        const end = authService.formatTimeQuick(row.end_date);
                        row.endDate = end;
                        const period = row.startDate + " - " + row.endDate;
                        row.period = period;
                        return row
                    })
                    dataa.sort((a: any, b: any) => (authService.checkNewest(a.endDate, b.endDate)) ? 1 : -1)
                    setData(dataa);
                }
            })
    };


    const unSub = (id: number) => {
        authService.ToggleParticipation(id);
        test();
    }
    async function test() {
        // console.log('start timer');
        await new Promise(resolve => setTimeout(resolve, 1600));
        // console.log('after 1 second');
        window.location.reload()
    }

    const useRefresh = () => {
        const [refresh, setRefresh] = React.useState(0)

        return () => setRefresh(refresh + 1)
    }
    const formatTime = (time: any) => {
        require('moment/locale/el');
        let dateFormatted = moment.unix(time).format('LL');
        return dateFormatted;
    }
    // const appendDeclareBtn = () => {
    //     if (props.title != "News") {
    //         <Button
    //             size='large'
    //             className={classes.DeclareBtn}
    //             onClick={(getMoviesFromApiAsync) => navigate("/news-post")}
    //         >
    //             {t('ΔΗΛΩΣΕ ΣΥΜΜΕΤΟΧΗ')}<RightOutlined />
    //         </Button>
    //     }
    // }

    // const model = (id: string) => {
    //     console.log(id);
    //     ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //     <Modal id={+id} title='' />
    //     console.log("model");

    // }

    const [currentPage, setCurrentPage] = useState(1);

    // const isSigned = (id: number) => {
    //     const userData: any = window.localStorage.getItem("userData");
    //     let obj = JSON.parse(userData);
    //     const actions = obj.volunteering_actions;
    //     console.log(actions)
    //     let item1 = actions.find((i: any) => i.id == id);

    //     if (item1)
    //         return true
    //     else
    //         return false
    // }

    useEffect(() => {
        // authService.getUserProfile()
        const fetchD = async () => {

            const data = await fetchData();
            await authService.getUserProfile();
        };
        fetchD();

    }, [setData])

    const [posts, setPosts] = useState([]);

    var params = {
        method: 'GET',
        headers: {
            "cache-control": 'no-cache',
            pragma: 'no-cache',
        }
    };

    return (
        <>
            <div className={classes.container}>
                <Row justify="center">
                    {data &&
                        data.slice(current * 4, 4 * current + 4).map((data) => {
                            return (
                                // <Post />
                                <Col className={classes.postCol} lg={12} style={{ borderRight: '1px dashed #2a576e' }}>
                                    <div key={data.id} className={classes.Post}>
                                        <Link to={`../${parent}/${page}/${data.id}`}>
                                            <img className={classes.ImagePost} src={data.image.url} alt={data.image.description} />
                                        </Link>
                                        <div className={classes.Date}>
                                            {/* {data.created} */}
                                            <p lang='el' style={{ textTransform: "uppercase" }}>{formatTime((props.title == "News") ? data.created : data.start_date)}</p>
                                        </div>
                                        <Link to={`../${parent}/${page}/${data.id}`}>
                                            <div className={classes.TitlePost}>
                                                {data.title}
                                            </div>
                                        </Link>
                                        <div className={classes.ExcerptPost}>
                                            {parse(`${data.description}`)}{ }
                                        </div>
                                        <Link to={`../${parent}/${page}/${data.id}`}>
                                            <Button
                                                size='large'
                                                className={classes.PostBtn}
                                            >
                                                {t('ΠΕΡΙΣΣΟΤΕΡΑ')}<RightOutlined />
                                            </Button>
                                        </Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                        {props.title != "News" ? <Modal id={data.id} title={data.title} start_date={data.start_date} /> : ""}
                                    </div>
                                </Col>
                            )
                        })}
                </Row>
                <Pagination className={classes.pagination} defaultCurrent={0} onChange={onChange} total={data.length * 2.5} />
            </div>
        </>
    )
}

export default Posts