import { Component } from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom"
import "./App.css";
import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'

import AuthService from './services/auth.service';
import IUser from './types/user.type';

import { I18nextProvider } from "react-i18next"
import i18n from "./translation"

import EventBus from "./common/EventBus";
// import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import Header from "./components/Header/Header"
import Footer from "./components/Footer/Footer"

import Home from './pages/Home/Home'
import Actions from './pages/Actions/Actions'
import News from './pages/News/News'
import ViewAction from './pages/ViewAction/ViewAction'
import ViewNewsPost from './pages/ViewNewsPost/ViewNewsPost'
import Login from './pages/Login/Login'
import VolunteerArea from './pages/VolunteerArea/VolunteerArea'
import Forum from './pages/Forum/Forum'
import Thread from './pages/Forum/Thread/Thread'
import Contact from './pages/Contact/Contact'
import Register from './pages/Register/Register'
import GDPR from './pages/GDPR/GDPR'
import ConfidentialityStatement from "./pages/ConfidentialityStatement/ConfidentialityStatement";

type Props = {};

type State = {
    // showModeratorBoard: boolean,
    // showAdminBoard: boolean,
    // currentUser: IUser | undefined
}

class App extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        // this.logOut = this.logOut.bind(this);

        this.state = {
            // showModeratorBoard: false,
            // showAdminBoard: false,
            // currentUser: undefined,
        };
    }
    componentDidMount() {
        //  AuthService.refreshToken(window.location.href);
    }

    render() {
        return (
            <I18nextProvider i18n={i18n}>
                <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/actions" element={<Actions />} />
                    <Route path="/news" element={<News />} />
                    <Route path="/action" element={<ViewAction />} />
                    <Route path="/news/post/:id" element={<ViewNewsPost />} />
                    <Route path="/actions/action/:id" element={<ViewAction />} />
                    <Route path="/forum" element={<Forum />} />
                    <Route path="/forum/thread/:id" element={<Thread />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/volunteer-area" element={<VolunteerArea />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/gdpr" element={<GDPR />} />
                    <Route path="/confidentiality" element={<ConfidentialityStatement />} />

                </Routes>
                <Footer />
            </I18nextProvider>
        );
    }
}

export default App;