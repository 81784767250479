import React, { useState, useEffect } from "react"
import { Row, Col, Select, Breadcrumb } from 'antd'
import classes from './styles.module.css'
import { useTranslation } from "react-i18next";
import Posts from "../Home/components/Posts"
import { min } from "moment";

const News = () => {
    const { t } = useTranslation()
    const [postType, setType] = useState<string>("News");

    return (
        <>
            <div className={classes.container}>
                <Row justify="center">
                    <Col lg={24}>
                        <div className={classes.title}>
                            {t('ΝΕΑ')}
                        </div>
                        <div className={classes.subTitle}>
                            {t('Διαβάστε όλα τα τελευταία νέα μας')}
                        </div>
                    </Col>
                </Row>
            </div>
            <div className={classes.breadcrumbContainer}>
                <Row justify="center">
                    <Col xs={24} sm={24} md={15} lg={15}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <a href="/">ΑΡΧΙΚΗ ΣΕΛΙΔΑ</a>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>ΝΕΑ</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </div>

            <Posts title={postType} handler={Math.max}/>
        </>
    );
};

export default News