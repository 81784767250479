import { Component } from "react";
import React, { useState, useEffect } from "react"
import { Row, Col, Breadcrumb, Button, Checkbox, Form, Input } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import { Link, useNavigate, Navigate } from "react-router-dom"
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"

import AuthService from "../../services/auth.service";



type Props = {};

type State = {
    redirect: string | null,
    username: string,
    password: string,
    loading: boolean,
    message: string
};

export default class Login extends Component<Props, State> {
    
    constructor(props: Props) {
        super(props);
        this.handleLogin = this.handleLogin.bind(this);

        this.state = {
            redirect: null,
            username: "",
            password: "",
            loading: false,
            message: ""
        };
        
    }
    // const { t } = useTranslation()
    // const url = new URL(window.location.href);
    // const navigate = useNavigate();
    // const [html, setHTML] = useState("");
    // const Login = () => {

    //     const onFinish = (values: any) => {
    //         console.log('Success:', values);
    //     };
    //     const onFinishFailed = (errorInfo: any) => {
    //         console.log('Failed:', errorInfo);
    //     };
    //     const[error, setError] = useState("");

    //     onSubmit = async (values: any) => {
    //         console.log("Values: ", values);
    //         setError("");

    //         fetch(`${process.env.REACT_APP_SERVER}/oauth/authorize?response_type=code&client_id=0uUDCoWj33hlcHTCQIEl6mgs3tt86nFD&redirect_uri=https://svaa-serres-app.dotsoft.gr/login&scope=dotsoft`, {
    //             method: 'GET',
    //             headers: {
    //                 "API-KEY": "MXtthZ2ewCmrWQzUj9VPVyOyqqqv6VLe",
    //                 "Content-Type": "application/json"
    //             }
    //         })
    //             .then(response => {
    //                 return response.text()
    //             })
    //             .then(data => setHTML(data))
    //     }

    //     getParameters() {
    //         let paramString = window.location.href.split('?')[1];
    //         let params_arr = paramString.split('&');
    //         for (let i = 0; i < params_arr.length; i++) {
    //             let pair = params_arr[i].split('=');
    //             return pair[1];
    //         }
    //     }


    // }

    // const getToken = () => {
    //     fetch(`${process.env.REACT_APP_SERVER}/oauth/authorize?response_type=code&client_id=0uUDCoWj33hlcHTCQIEl6mgs3tt86nFD&redirect_uri=https://svaa-serres-app.dotsoft.gr/login&scope=dotsoft`, {
    //         method: 'GET',
    //         headers: {
    //             "API-KEY": "MXtthZ2ewCmrWQzUj9VPVyOyqqqv6VLe",
    //             "Content-Type": "application/json"
    //         }
    //     })
    //         .then(response => {
    //             return response.text()
    //         })
    //         .then(data => {
    //             // console.log(data);
    //         })
    // }

    // useEffect(() => {
    //     AuthService.login().then(
    //         () => {
    //             this.setState({
    //                 redirect: "/profile"
    //             });
    //         },
    //         error => {
    //             const resMessage =
    //                 (error.response &&
    //                     error.response.data &&
    //                     error.response.data.message) ||
    //                 error.message ||
    //                 error.toString();

    //             this.setState({
    //                 loading: false,
    //                 message: resMessage
    //             });
    //         }
    //     );
    //     getToken()
    // }, [])
    // )
    componentDidMount() {
        const currentUser = AuthService.getCurrentUser();
        // AuthService.getUserProfile();
        // AuthService.getActions()
        //  new Promise(resolve => setTimeout(resolve, 1800));
        if (currentUser) {
            
            this.setState({ redirect: window.localStorage.getItem("location") });
            <Navigate to={`${window.localStorage.getItem("location")}`}/>
        };
        AuthService.login(window.location.href);
        // <Navigate to={'/volunteer-area'}/>
    }

    componentWillUnmount() {
        // window.location.reload();
    }

    // validationSchema() {
    //     return Yup.object().shape({
    //         username: Yup.string().required("This field is required!"),
    //         password: Yup.string().required("This field is required!"),
    //     });
    // }

    handleLogin(formValue: { username: string; password: string }) {
        const { username, password } = formValue;

        this.setState({
            message: "",
            loading: true
        });


        AuthService.login(window.location.href)
        // .then(
        //     () => {
        //         this.setState({
        //             redirect: "/profile"
        //         });
        //     },
        //     (error: any)=> {
        //         const resMessage =
        //             (error.response &&
        //                 error.response.data &&
        //                 error.response.data.message) ||
        //             error.message ||
        //             error.toString();

        //         this.setState({
        //             loading: false,
        //             message: resMessage
        //         });
        //     }
        // );
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }

        const { loading, message } = this.state;

        const initialValues = {
            username: "",
            password: "",
        };

        return (
            <>
                {/* <div className={classes.container}>
            <Row justify="center">
                <Col lg={24}>
                    <div className={classes.title}>
                        {t('ΠΕΡΙΟΧΗ ΕΘΕΛΟΝΤΩΝ')}
                    </div>
                </Col>
            </Row>
        </div>
        <div className={classes.breadcrumbContainer}>
            <Row justify="center">
                <Col xs={24} sm={24} md={15} lg={15}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <a href="/">{t('ΑΡΧΙΚΗ ΣΕΛΙΔΑ')}</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <a href="/volunteers-aeria">{t('ΠΕΡΙΟΧΗ ΕΘΕΛΟΝΤΩΝ')}</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <p style={{ textTransform: "uppercase" }}>{t('ΕΙΣΟΔΟΣ')}</p>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
        </div>
        <div className={classes.mainContainer}>
            <div className={classes.login}>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <div className={classes.title}>
                            {t('Είσοδος')}
                        </div>
                        <Form
                            name="basic"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ maxWidth: 460 }}
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="on"
                        >
                            <Form.Item
                                label="Όνομα Χρήστη"
                                name="Username"
                                rules={[{ required: true, message: 'Please input your username!' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Κωδικός Χρήστη"
                                name="password"
                                rules={[{ required: true, message: 'Please input your password!' }]}
                            >
                                <Input.Password />
                            </Form.Item>

                            <Form.Item name="remember" valuePropName="checked" wrapperCol={{ span: 16 }}>
                                <Checkbox>{t('Να με θυμάσαι')}</Checkbox>
                            </Form.Item>

                            <Form.Item wrapperCol={{ span: 24 }}>
                                <Button
                                    type="primary" htmlType="submit"
                                    className={classes.PostBtn}
                                    onClick={onSubmit}
                                >
                                    {t('ΕΙΣΟΔΟΣ ΣΤΟ ΣΥΣΤΗΜΑ')} < RightOutlined />
                                </Button>
                            </Form.Item>

                            <Form.Item name="forgot" wrapperCol={{ span: 24 }}>
                                <a href="#"><u>{t('Επανάκτηση κωδικού σε περίπτωση απώλειας')}</u></a>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </div>
            <div className={classes.register}>
                {t('Γίνε εθελοντής. Κάνε εγγραφή τώρα!')}
            </div>
        </div> */}
            </>
        );
    }
}