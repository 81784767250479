import React, { useEffect, useState } from 'react'
import classes from './styles.module.css'
import { Button, Checkbox, DatePicker, DatePickerProps, Form, Input, Space } from 'antd'
import { useTranslation } from "react-i18next";
import { RightOutlined } from '@ant-design/icons';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import authService from '../../services/auth.service';
import { useNavigate, Link } from "react-router-dom";
import TextArea from 'antd/es/input/TextArea';
import 'dayjs/locale/el';
import locale from 'antd/es/date-picker/locale/el_GR';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';

interface Action {
  id: number;
  title: string;
  start_date: string;
}

export default function Modal(data: Action) {
  const { t } = useTranslation()
  const navigate = useNavigate();

  let webformId = '';
  const [form] = Form.useForm();
  const [modal, setModal] = useState(false);
  const [textField, setTextField] = useState<any>()
  const [textAreaValue, setTextAreaValue] = useState<string>("")
  const [callBody, setCallBody] = useState<Array<{ title: string, value: dayjs.Dayjs | null | boolean | string }>>([])
  const [formFields, setFormFields] = useState<any>();
  let fields: any = [];

  const getFields = (webform1: any) => {
    fetch(`https://ethelontismos-admin.serres.gr/webform_rest/${webform1}/fields`, {
      method: 'GET',
      headers: new Headers({
        "Authorization": `Bearer ${window.localStorage.getItem("access_token")}`
      })
    })
      .then(response => {
        return response.json()
      })
      .then(data => {
        setFormFields(data);


      })
  }

  const toggleModal = async () => {

    let objIndex = -1;
    objIndex = callBody.findIndex((obj => obj.value != false));

    console.log(objIndex);

    if (objIndex <= 5 && objIndex >= 0) {
      callBody[objIndex].value = false
      console.log(callBody[objIndex]);
      { console.log(callBody)! }
    }
    { console.log(callBody)! }
    if (!authService.getCurrentUser()) {
      window.localStorage.setItem("location", window.location.href);
      (window as Window).location = `${process.env.REACT_APP_SERVER}/oauth/authorize?response_type=code&client_id=0uUDCoWj33hlcHTCQIEl6mgs3tt86nFD&redirect_uri=${process.env.REACT_APP_URL}/login&scope=dotsoft`

    } else {
      let webform1 = await fetch(`https://ethelontismos-admin.serres.gr/api/el/volunteering-actions/${data.id}`, {
        method: 'GET',
        headers: new Headers({
          "API-KEY": `${process.env.REACT_APP_API_KEY}`
        })
      })
        .then(response => {
          return response.json()
        })
        .then(async (data) => {
          return data.webform;

        })
      getFields(webform1)
      setModal(!modal)
    }
  }



  const changeDate: DatePickerProps['onChange'] = (date: Dayjs) => {
    const newDate = dayjs(date).format('YYYY-MM-DDΤh:m:s')
    console.log(newDate);

    const objIndex = callBody.findIndex((obj => obj.title == 'imerominia_gennisis'));

    console.log("Before update: ", callBody[objIndex])

    callBody[objIndex].value = newDate;

    console.log("After update: ", callBody[objIndex])
  };


  const onChangeField = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: string) => {
    console.log(e.target.value);
    console.log(key);
    console.log(callBody);

    const objIndex = callBody.findIndex((obj => obj.title == key));

    console.log("Before update: ", callBody[objIndex])

    callBody[objIndex].value = e.target.value;

    console.log("After update: ", callBody[objIndex])


  };


  const onChangeCheckbox = (e: CheckboxChangeEvent, key: string) => {
    console.log(e.target.checked);
    const objIndex = callBody.findIndex((obj => obj.title == key));

    console.log("Before update: ", callBody[objIndex]);

    callBody[objIndex].value = e.target.checked;

    console.log("After update: ", callBody[objIndex]);
  };


  async function someFunc(id: any) {
    console.log(callBody);

    if (!authService.getCurrentUser) {

    } else {
      if (callBody.length >= 0) {

        callBody.unshift({ title: 'webform_id', value: `${webformId}` })
        const found = callBody.find(obj => obj.value == undefined || obj.value == 'Invalid Date' || obj.value == '' || obj.value == false)

        let outputObject = {};

        if (!found) {
          console.log("TEST");
          outputObject = callBody.reduce((acc: any, curr) => {
            acc[curr.title] = `${curr.value}`;
            return acc;
          }, {});
          console.log(outputObject);


          await fetch(`${process.env.REACT_APP_SERVER}/webform_rest/submit/`, {
            method: 'POST',
            headers: new Headers({
              'Accept': 'application/json',
              'Origin': '',
              "Authorization": `Bearer ${window.localStorage.getItem("access_token")}`,
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify(outputObject)
          })
            .then(response => {
              return response.json()
            })
            .then(data => {
              console.log(data)
            })
          await authService.ToggleParticipation(id)
          toggleModal()
          await authService.getUserProfile()
        }
        else {
          console.log("TOGGLE");
          await authService.ToggleParticipation(id)
          toggleModal()
          await authService.getUserProfile()
        }
      }
    }
  }

  let Idnum = data.id;


  const unSub = async (id: any) => {
    await authService.ToggleParticipation(id);
    await authService.getUserProfile();
    await toActions()
  }
  async function test() {
    // console.log('start timer');
    await new Promise(resolve => setTimeout(resolve, 1500));
    // console.log('after 1 second');

  }
  async function toActions() {
    if (window.location.href != `${process.env.REACT_APP_URL}/actions`) {
      navigate("/actions")
    } else
      window.location.reload()
  }
  if (modal) {
    document.body.classList.add('activeModal')
  } else {
    document.body.classList.add('activeModal')
  }
  function call() {
    console.log('CALL');
    console.log("-------------------");
    console.log(callBody);




    callBody.length = 0;
    console.log(formFields);
    if (formFields) {
      Object.keys(formFields).forEach(function (key: any) {

        console.log(formFields[key]['#webform']);
        webformId = formFields[key]['#webform'];
        fields.push({ title: formFields[key]['#title'], type: formFields[key]['#type'], key: formFields[key]['#webform_key'] })
        console.log("CallBody LENGTH before:", callBody.length)
        console.log("formFields LENGTH:", Object.keys(formFields).length)
        if (callBody.length < Object.keys(formFields).length) {
          callBody.push({ title: formFields[key]['#webform_key'], value: textField })
          console.log("CallBody LENGTH after:", callBody.length)
        }
      })
    }
  }



  function createElement(field: any, key: string, formItemName: string) {

    switch (field[key]) {
      case 'date':
        return (
          <Space direction="vertical">
            <DatePicker onChange={changeDate} locale={locale} className={classes.datePicker} />
          </Space>
        )
      case 'textfield':
        return <Input placeholder="" onChange={event => onChangeField(event, formItemName)} style={{ width: 280 }} />
      case 'textarea':
        return <TextArea onChange={event => onChangeField(event, formItemName)} rows={4} className={classes.textarea} />
      case 'checkbox':
        return <Checkbox className={classes.modalCheckbox} onChange={event => onChangeCheckbox(event, formItemName)}>{field['#title']}</Checkbox>
      default:
        return field['#title']
    }

  }
  const formatTime = (time: any) => {
    require('moment/locale/el');

    let dateFormatted;
    try {
      if ((dateFormatted == undefined || 'Invalid date') && (typeof time) == 'number') {
        dateFormatted = moment.unix(time).format('LL');
      } else {
        dateFormatted = moment(time, 'D/M/YYYY HH:mm').format('LL');
      }

    } catch (err) {
      console.error(err);
      return null; // Or some other default value if needed
    }

    return dateFormatted;
  }

  useEffect(() => {

  }, [])

  return (
    <>
      {((authService.isSigned(Idnum)) ?
        <Button
          size='large'
          className={classes.DangerBtn}
          onClick={() => { unSub(Idnum) }}
        >
          {t('ΑΠΕΓΓΡΑΦΗ')}<RightOutlined />
        </Button>
        :
        <Button
          size='large'
          className={classes.DeclareBtn}
          onClick={toggleModal}
        >
          {t('ΔΗΛΩΣΕ ΣΥΜΜΕΤΟΧΗ')}<RightOutlined />
        </Button>

      )}

      {
        modal && (
          <div className={classes.modal}>
            <div className={classes.overlay} onClick={toggleModal}></div>
            <div className={classes.modalContent}>
              <p className={classes.modalMainTitle}>{t('Επιβεβαίωση Δήλωσης Συμμετοχής')}</p>
              <p className={classes.modalTitle}>{t(data.title)}</p>
              <div className={classes.Date}>
                {/* {data.created} */}
                <p lang='el' style={{ textTransform: "uppercase" }}>{formatTime(data.start_date)}</p>
              </div>

              <Form form={form}>
               

                    {call()!}

                    <div className={classes.formContainer}>
                      {fields.map((field: any) => (

                        <div style={{ color: 'white' }} className={classes.fieldContainer}>
                          {field['title']}
                          <Form.Item
                            className={classes.forItem}
                            name={field['key']}
                            rules={[
                              {
                                required: true,
                                message: `* ${t('Το πεδίο είναι υποχρεωτικό')}  ${field['key']} ${textAreaValue}`,
                              },
                            ]}
                          >
                            {Object.keys(field).map(key => (
                              <div key={key}>

                                <div className={classes.fieldBox}>{createElement(field, key, field['key'])}</div>

                              </div>
                            ))}
                          </Form.Item>

                        </div>

                      ))}
                    </div>

                <br />
                <br />

                <Button
                  size='large'
                  className={classes.DeclareBtn}
                  htmlType="submit"
                  onClick={() => { someFunc(Idnum) }}
                >
                  {t('ΕΠΙΒΕΒΑΙΩΣΗ')}<RightOutlined />
                </Button>
                <button className={classes.closeModal} onClick={toggleModal}>x</button>
              </Form >
            </div >
          </div >
        )
      }
    </>
  )

};