import React from "react";
import { CloseOutlined, CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'


export const SidebarData = [
    // {
    //     title: 'ΤΟΜΕΙΣ',
    //     path: '',
    //     state: { page: 'sections' },
    //     iconClosed: <CaretDownOutlined />,
    //     iconOpened: <CaretUpOutlined />,
    //     toggle: false,
    //     subNav: [
    //         {
    //             title: 'ΤΟΜΕΑΣ Α',
    //             path: '/section-a',
    //             state: { page: 'users' },
    //             toggle: true,
    //         },
    //         {
    //             title: 'ΤΟΜΕΑΣ Β',
    //             path: '/actions',
    //             state: { page: 'section-b' },
    //             toggle: true,
    //         }
    //     ]
    // },
    {
        id: 1,
        title: 'ΑΡΧΙΚΗ',
        path: '/',
        state: { page: 'home' },
        toggle: true,
    },
    {
        id: 2,
        title: 'ΔΡΑΣΕΙΣ',
        path: '/actions',
        state: { page: 'actions' },
        toggle: true,
    },
    {
        id: 3,
        title: 'ΝΕΑ',
        path: '/news',
        state: { page: 'news' },
        toggle: true,
    },
    {
        id: 4,
        title: 'FORUM',
        path: '/forum',
        state: { page: 'forum' },
        toggle: true,
    },
    {
        id: 5,
        title: 'ΕΠΙΚΟΙΝΩΝΙΑ',
        path: '/contact',
        state: { page: 'contact' },
        toggle: true,
    },
    {
        id: 6,
        title: 'ΠΕΡΙΟΧΗ ΕΘΕΛΟΝΤΩΝ',
        path: '/volunteer-area',
        state: { page: 'volunteer-area' },
        toggle: true,
    },
]