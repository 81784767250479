import React from "react";
import classes from './styles.module.css';
import { useTranslation } from "react-i18next";
import { Row, Col, Select, Breadcrumb, Button, Checkbox, Form, Input, Switch, Table } from 'antd'


const ConfidentialityStatement = () => {

    const { t } = useTranslation()

    return (
        <>
            <div className={classes.container}>
                <Row justify="center">
                    <Col lg={24}>
                        <div className={classes.title}>
                            {t('ΔΗΛΩΣΗ ΕΧΕΜΥΘΕΙΑΣ')}
                        </div>
                    </Col>
                </Row>
            </div>
            <div className={classes.breadcrumbContainer}>
                <Row justify="center">
                    <Col xs={24} sm={24} md={15} lg={15}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <a href="/">{t('ΑΡΧΙΚΗ ΣΕΛΙΔΑ')}</a>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <p style={{ textTransform: "uppercase" }}>{t('ΔΗΛΩΣΗ ΕΧΕΜΥΘΕΙΑΣ')}</p>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </div>
            <div className={classes.mainContainer}>
                {t(`Ο/Η υπογράφων/ουσα ως συμμετέχων/ουσα με την ιδιότητα του εθελοντή στη δράση για
τη διανομή συσσιτίου με στόχο την παροχή βασικών αγαθών, σε εκείνο το τμήμα του
πληθυσμού που δεν μπορεί να καλύψει τις ανάγκες του, την αντιμετώπιση του φαινομένου
της ακραίας φτώχειας και του κοινωνικού αποκλεισμού μέσω της υποστήριξης ομάδων του
πληθυσμού που πλήττονται ή απειλούνται από τη φτώχεια και τον κοινωνικό αποκλεισμό,
την ενίσχυση της κοινωνικής συνοχής και την πρόληψη φαινομένων περιθωριοποίησης και
κοινωνικού αποκλεισμού και την ενδυνάμωση και κοινωνική ενσωμάτωση των ευπαθών
κοινωνικά και ειδικότερα των ατόμων ευρισκόμενων σε κατάσταση φτώχειας μέσα από
ενέργειες ψυχοκοινωνικής υποστήριξης και πληροφόρησης, δεσμεύομαι στα κάτωθι:`)}
                <br />
                <br />
                <ul className={classes.list}>
                    <li>{t(`Αναλαμβάνω να τηρώ απόλυτη εχεμύθεια σχετικά με όποια ευαίσθητα στοιχεία ή
πληροφορίες ή υλικό θα περιέλθει σε γνώση μου στο πλαίσιο της συμμετοχής μου
στην παραπάνω δράση. Για τη δήλωση αυτή, ως εμπιστευτικό και απόρρητο
θεωρούνται όλες οι πληροφορίες που παρέχονται. Σε περίπτωση αμφιβολίας, όλες
οι πληροφορίες που περιέρχονται σε γνώση μου θεωρούνται άκρως εμπιστευτικές.
Η εμπιστευτικότητα της κάθε πληροφορίας δεν επηρεάζεται από το εάν αυτή
περιήλθε σε γνώση μου προφορικά, εγγράφως, ηλεκτρονικά ή με οποιονδήποτε
άλλο τρόπο.`)}</li>
                    <br />

                    <li>{t(`Τα στοιχεία των αιτούντων και των τελικών δικαιούχων είναι απόρρητα και τα
διαχειρίζονται μόνο οι αρμόδιοι υπάλληλοι της δομής και της Κοινωνικής
Υπηρεσίας του Δήμου. Πρόσβαση στα στοιχεία αυτά μπορούν να έχουν και τα μέλη
της Ομάδας Έργου, που δεσμεύονται για την εχεμύθειά τους.`)}</li>
                    <br />
                    <li>{t(`Να μην αποκαλύψω σε τρίτους εμπιστευτικές πληροφορίες που μου δόθηκαν ή να
χρησιμοποιήσω ο ίδιος, στοιχεία που θα περιέλθουν σε γνώση μου κατά την
εκτέλεση της δράσης και να απέχω γενικά από κάθε ενέργεια που μπορεί να θίξει
κεκτημένα δικαιώματα των δικαιούχων, ούτε να κοινοποιώ στοιχεία, έγγραφα και
πληροφορίες των οποίων λαμβάνω γνώση σε σχέση με τη δράση.`)}</li>
                </ul>
                <br />
                <br />
                {t(`Η υποχρέωση εμπιστευτικότητας ιδίως περιλαμβάνει :`)}
                <br />
                <br />
                <ul className={classes.list}>
                    <li>{t(`Τη μη περαιτέρω και με οποιονδήποτε τρόπο μεταβίβαση - διάθεση –
δημοσιοποίηση – αποκάλυψη των πληροφοριών σε οποιονδήποτε τρίτο χωρίς την
αντίστοιχη συγκατάθεση,`)}</li>
                    <br />

                    <li>{t(`Την απαγόρευση εκμετάλλευσης των πληροφοριών και αποτελεσμάτων για
οποιονδήποτε άλλο σκοπό`)}</li>
                    <br />
                    <li>{t(`Την απαγόρευση της με οποιονδήποτε τρόπο και μέσο αντιγραφής και
αναπαραγωγής των εμπιστευτικών πληροφοριών και αποτελεσμάτων για άλλο
σκοπό.`)}</li>
                </ul>

            </div >
        </>
    );
};

export default ConfidentialityStatement