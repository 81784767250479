import React, { useEffect, useState } from "react"
import { Row, Col, Select, Breadcrumb, Pagination, Space } from 'antd'
import { EnvironmentOutlined, ClockCircleOutlined, SearchOutlined, HeartOutlined, ShakeOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import classes from './styles.module.css'
import { useTranslation } from "react-i18next";
import type { PaginationProps, MenuProps } from 'antd'
import { useNavigate, Link } from "react-router-dom";
import moment from "moment";
import parse from 'html-react-parser';
import { log } from "console";
import authService from "../../services/auth.service";


const Forum = () => {
    const [current, setCurrent] = useState(0);
    // const [data, setData] = React.useState<any[]>([])
    const [open, setOpen] = React.useState<any[]>([])
    const [closed, setClosed] = React.useState<[{}]>([{}])
    const [topics, setTopics] = React.useState<any[]>([])
    const [topic, setTopic] = React.useState<{ title: string, description: string, id: string, comments: any, created_date: string, end_date: string, is_open: boolean, is_pinned: boolean, is_visible: boolean }>({ id: '', title: '', description: '', comments: [], created_date: '', end_date: '', is_open: false, is_pinned: false, is_visible: false })

    const handleChange = (value: string) => {
        switch (value) {
            case 'open': {
                showOpen();
                break;
            }
            case 'closed': {
                showClosed();
                break;
            }
            default: {
                showAll();
                break;
            }
        }
    };

    const showAll = () => {
        console.log(topics);
    }
    const showOpen = () => {
        console.log(open);

    }
    const showClosed = () => {
        console.log(closed);

    }

    const formatTime = (time: any) => {
        require('moment/locale/el');
        let dateFormatted = moment.unix(time).format('LL');
        return dateFormatted;
    }
    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <a target="_blank" rel="noopener noreferrer" onClick={showAll!}>
                    Όλα
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a target="_blank" rel="noopener noreferrer" onClick={showOpen!}>
                    Ανοιχτό
                </a>
            ),
            disabled: false,
        },
        {
            key: '3',
            label: (
                <a target="_blank" rel="noopener noreferrer" onClick={showClosed!}>
                    Κλειστό
                </a>
            ),
            disabled: false,
        }
    ];


    const onChange: PaginationProps['onChange'] = (page) => {
        // console.log(page - 1);
        setCurrent(page - 1);
    };
    const functionThatReturnsAPromise = (item: any) => { //a function that returns a promise
        return Promise.resolve('ok')
    }
    const doSomethingAsync = async (item: any) => {
        return functionThatReturnsAPromise(item)
    }
    const { t } = useTranslation()
    // const getData = async () => {
    //     return Promise.all(topics.map(item => doSomethingAsync(item)))
    // }
    const delay = (ms: any) => new Promise(res => setTimeout(res, ms));
    const getTopic = async () => {
        fetch(`${process.env.REACT_APP_SERVER}/api/el/forum-topics/8`, {
            method: 'GET',
            mode: 'cors',
            headers: { "API-KEY": "MXtthZ2ewCmrWQzUj9VPVyOyqqqv6VLe" }
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                // myActions = data.volunteering_actions;
                // console.log(myActions);
                // filteredResult = myActions.find((e: any) => e.id == id);
                // newArr = data.volunteering_actions.map((item:any, i:any) => (data.volunteering_actions.splice(1,i)) );

                // console.log(data);
                topics.push(data)
                // console.log(topics);
                // console.log(topics.length);


                setTopic(topics[0])

                // actions.map((action: any) => {
                //     if (action.id == id) {
                //         ActionTitle = action.title;
                //         console.log(ActionTitle);

                //     }
                // })
            });
        await delay(1000);
        topics.map((data: any) => {

            switch (data.is_open) {
                case true: {
                    open.push(data)

                    break;
                }
                default: {
                    closed.push(data);
                    break;
                }
            }


        })
        // console.log(open);
    }
    const getTopics = async () => {
        await fetch(`${process.env.REACT_APP_SERVER}/api/el/forum-topics`, {
            method: 'GET',
            mode: 'cors',
            headers: { "API-KEY": "MXtthZ2ewCmrWQzUj9VPVyOyqqqv6VLe" }
        })
            .then(response => {
                return response.json()
            })
            .then(data => {


                // const data_topics = data.forum_topics.map((topic: any) => {
                //     console.log(topidc);

                //     // data_topics.sort((a: any, b: any) => (authService.checkNewest(a.createDate, b.createDate)) ? 1 : -1)

                //     setTopics(data_topics);

                //     console.log(data);
                //     // setTopics(data.forum_topics)

                //     return topic
                // });

                setTopics(data.forum_topics)
            });
        // await delay(
        // await delay(1000);
        // topics.map((data: any) => {

        //     switch (data.is_open) {
        //         case true: {
        //             open.push(data)

        //             break;
        //         }
        //         default: {
        //             closed.push(data);
        //             break;
        //         }
        //     }


        // })
        // console.log(open);

    }


    useEffect(() => {
        // getTopic()
        authService.getUserProfile();
        getTopics()

    }, [])

    return (
        <>
            <div className={classes.container}>
                <Row justify="center">
                    <Col lg={24}>
                        <div className={classes.title}>
                            {t('ΔΗΜΟΣΙΟ FORUM')}
                        </div>
                    </Col>
                </Row>
            </div>
            <div className={classes.breadcrumbContainer}>
                <Row justify="center">
                    <Col xs={24} sm={24} md={15} lg={15}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <a href="/">ΑΡΧΙΚΗ ΣΕΛΙΔΑ</a>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>FORUM</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </div>
            <div className={classes.filterWrapper}>
                <Row>
                    <Col xs={16} sm={10} md={7} lg={7}>

                        <Space wrap className={classes.filter}>
                            Κατάσταση:
                            <Select
                                className={classes.Select}
                                defaultValue="Όλα"
                                style={{ width: 120 }}
                                onChange={handleChange}
                                options={[
                                    { value: 'all', label: 'Όλα' },
                                    { value: 'open', label: 'Ανοιχτό' },
                                    { value: 'closed', label: 'Κλειστό' },
                                ]}
                            />
                        </Space>
                        {/* <Dropdown menu={{ items }} trigger={['click']} >
                            <a onClick={(e) => e.preventDefault()}>
                                <Space>
                                    Κατάσταση
                                    <DownOutlined />
                                </Space>
                            </a>
                        </Dropdown> */}
                    </Col>
                </Row>
            </div>
            <div className={classes.mainContainer}>
                {topics &&
                    topics.map((topic: any) => {
                        return (< Link to={`../forum/thread/${topic.id}`}>
                            <div className={classes.wrap}>
                                <Row>
                                    <Row style={{ width: '100%' }}>
                                        <Col xs={24} sm={24} md={24} lg={23}>
                                            <div className={classes.title}>
                                                {t(topic.title)}
                                            </div>

                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={1}>
                                            {topic.is_open ?
                                                <UnlockOutlined className={classes.iconLock} style={{ backgroundColor: "#88c16d" }} /> :
                                                <LockOutlined className={classes.iconLock} style={{ backgroundColor: "#f63636" }} />

                                            }

                                        </Col>
                                    </Row>
                                    <Col xs={24} sm={24} md={24} lg={24}>
                                        <div className={classes.subTitle}>
                                            {parse(`${topic.description}`)}
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={3}>
                                        <div className={classes.param}>
                                            <p className={classes.bold}>{t(topic.comments.length)} </p><p className={classes.book}>{t('ΣΧΟΛΙΑ')}</p>
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={8}>
                                        <div className={classes.paramStart}>
                                            <p className={classes.bold}>{t('ΗΜ. ΚΑΤΑΧΩΡΗΣΗΣ')}: </p><p lang="el" className={classes.book}>{formatTime(topic.created_date)}</p>
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={13}>
                                        {topic.is_open ?
                                            <div className={classes.paramEnd} style={{ float: "right" }}>
                                                <p className={classes.bold}>{t('ΑΝΟΙΚΤΟ ΕΩΣ')}: </p><p lang="el" className={classes.book}>{formatTime(topic.end_date)}</p>
                                            </div> :
                                            <div className={classes.closed} style={{ float: "right" }}>
                                                <p className={classes.bold}>{t('ΚΛΕΙΣΤΟ')}</p>
                                            </div>

                                        }
                                    </Col>
                                </Row>
                            </div>
                            {/* <div className={classes.wrap}>
                                <Row>
                                    <Row style={{ width: '100%' }}>
                                        <Col xs={20} sm={22} md={22} lg={23}>
                                            <div className={classes.title}>
                                                {t(`Lorem ipsum dolor sit amet. Ut culpa incidunt est fugiat impedit`)}
                                            </div>

                                        </Col>
                                        <Col xs={4} sm={2} md={2} lg={1}>
                                            <UnlockOutlined className={classes.iconLock} style={{ backgroundColor: "#88c16d" }} />
                                        </Col>
                                    </Row>
                                    <Col xs={24} sm={24} md={24} lg={24}>
                                        <div className={classes.subTitle}>
                                            {t(`Ab vitae adipisci eos quia inventore quo sapiente fuga aut quia unde eos commodi sapiente 33 magnam dolores et molestiae iste. In deleniti quod a provident consequuntur ut dolor corrupti.
    
    Eos voluptatem eveniet sed officiis soluta id expedita tempore! Qui nisi odit cum dicta repellendus non repellat illo ut possimus consequatur`)}
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={5} md={3} lg={2}>
                                        <div className={classes.param}>
                                            <p className={classes.bold}>{t('7')} </p><p className={classes.book}>{t('ΣΧΟΛΙΑ')}</p>
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={18} md={10} lg={8}>
                                        <div className={classes.param} style={{ marginLeft: 19 }}>
                                            <p className={classes.bold}>{t('ΗΜ. ΚΑΤΑΧΩΡΗΣΗΣ')}: </p><p className={classes.book}>{t('2 ΝΟΕΜΒΡΙΟΥ 2022')}</p>
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={14}>
                                        <div className={classes.paramEnd} style={{ float: "right" }}>
                                            <p className={classes.bold}>{t('ΑΝΟΙΚΤΟ ΕΩΣ')}: </p><p className={classes.book}>{t('30 ΝΟΕΜΒΡΙΟΥ 2022')}</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div> */}
                        </Link>)
                    }
                    )}

                {/* 
                <div className={classes.wrap}>
                    <Row>
                        <Row style={{ width: '100%' }}>
                            <Col xs={24} sm={24} md={24} lg={23}>
                                <div className={classes.title}>
                                    {t(`Qui nisi odit cum dicta repellendus`)}
                                </div>

                            </Col>
                            <Col xs={24} sm={24} md={24} lg={1}>
                                <LockOutlined className={classes.iconLock} style={{ backgroundColor: "#f63636" }} />
                            </Col>
                        </Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <div className={classes.subTitle}>
                                {t(`Ab vitae adipisci eos quia inventore quo sapiente fuga aut quia unde eos commodi sapiente 33 magnam dolores et molestiae iste. In deleniti quod a provident consequuntur ut dolor corrupti.

Eos voluptatem eveniet sed officiis soluta id expedita tempore! Qui nisi odit cum dicta repellendus non repellat illo ut possimus consequatur`)}
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2}>
                            <div className={classes.param}>
                                <p className={classes.bold}>{t('7')} </p><p className={classes.book}>{t('ΣΧΟΛΙΑ')}</p>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={7}>
                            <div className={classes.param} style={{ marginLeft: 19 }}>
                                <p className={classes.bold}>{t('ΗΜ. ΚΑΤΑΧΩΡΗΣΗΣ')}: </p><p className={classes.book}>{t('2 ΝΟΕΜΒΡΙΟΥ 2022')}</p>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={15}>
                            <div className={classes.closed} style={{ float: "right" }}>
                                <p className={classes.bold}>{t('ΚΛΕΙΣΤΟ')}</p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className={classes.wrap}>
                    <Row>
                        <Row style={{ width: '100%' }}>
                            <Col xs={24} sm={24} md={24} lg={23}>
                                <div className={classes.title}>
                                    {t(`Qui nisi odit cum dicta repellendus`)}
                                </div>

                            </Col>
                            <Col xs={24} sm={24} md={24} lg={1}>
                                <LockOutlined className={classes.iconLock} style={{ backgroundColor: "#f63636" }} />
                            </Col>
                        </Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <div className={classes.subTitle}>
                                {t(`Ab vitae adipisci eos quia inventore quo sapiente fuga aut quia unde eos commodi sapiente 33 magnam dolores et molestiae iste. In deleniti quod a provident consequuntur ut dolor corrupti.

Eos voluptatem eveniet sed officiis soluta id expedita tempore! Qui nisi odit cum dicta repellendus non repellat illo ut possimus consequatur`)}
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2}>
                            <div className={classes.param}>
                                <p className={classes.bold}>{t('7')} </p><p className={classes.book}>{t('ΣΧΟΛΙΑ')}</p>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={7}>
                            <div className={classes.param} style={{ marginLeft: 19 }}>
                                <p className={classes.bold}>{t('ΗΜ. ΚΑΤΑΧΩΡΗΣΗΣ')}: </p><p className={classes.book}>{t('2 ΝΟΕΜΒΡΙΟΥ 2022')}</p>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={15}>
                            <div className={classes.closed} style={{ float: "right" }}>
                                <p className={classes.bold}>{t('ΚΛΕΙΣΤΟ')}</p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className={classes.wrap}>
                    <Row>
                        <Row style={{ width: '100%' }}>
                            <Col xs={24} sm={24} md={24} lg={23}>
                                <div className={classes.title}>
                                    {t(`Qui nisi odit cum dicta repellendus`)}
                                </div>

                            </Col>
                            <Col xs={24} sm={24} md={24} lg={1}>
                                <LockOutlined className={classes.iconLock} style={{ backgroundColor: "#f63636" }} />
                            </Col>
                        </Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <div className={classes.subTitle}>
                                {t(`Ab vitae adipisci eos quia inventore quo sapiente fuga aut quia unde eos commodi sapiente 33 magnam dolores et molestiae iste. In deleniti quod a provident consequuntur ut dolor corrupti.

Eos voluptatem eveniet sed officiis soluta id expedita tempore! Qui nisi odit cum dicta repellendus non repellat illo ut possimus consequatur`)}
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2}>
                            <div className={classes.param}>
                                <p className={classes.bold}>{t('7')} </p><p className={classes.book}>{t('ΣΧΟΛΙΑ')}</p>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={7}>
                            <div className={classes.param} style={{ marginLeft: 19 }}>
                                <p className={classes.bold}>{t('ΗΜ. ΚΑΤΑΧΩΡΗΣΗΣ')}: </p><p className={classes.book}>{t('2 ΝΟΕΜΒΡΙΟΥ 2022')}</p>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={15}>
                            <div className={classes.closed} style={{ float: "right" }}>
                                <p className={classes.bold}>{t('ΚΛΕΙΣΤΟ')}</p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className={classes.wrap}>
                    <Row>
                        <Row style={{ width: '100%' }}>
                            <Col xs={24} sm={24} md={24} lg={23}>
                                <div className={classes.title}>
                                    {t(`Qui nisi odit cum dicta repellendus`)}
                                </div>

                            </Col>
                            <Col xs={24} sm={24} md={24} lg={1}>
                                <LockOutlined className={classes.iconLock} style={{ backgroundColor: "#f63636" }} />
                            </Col>
                        </Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <div className={classes.subTitle}>
                                {t(`Ab vitae adipisci eos quia inventore quo sapiente fuga aut quia unde eos commodi sapiente 33 magnam dolores et molestiae iste. In deleniti quod a provident consequuntur ut dolor corrupti.

Eos voluptatem eveniet sed officiis soluta id expedita tempore! Qui nisi odit cum dicta repellendus non repellat illo ut possimus consequatur`)}
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={2}>
                            <div className={classes.param}>
                                <p className={classes.bold}>{t('7')} </p><p className={classes.book}>{t('ΣΧΟΛΙΑ')}</p>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={7}>
                            <div className={classes.param} style={{ marginLeft: 19 }}>
                                <p className={classes.bold}>{t('ΗΜ. ΚΑΤΑΧΩΡΗΣΗΣ')}: </p><p className={classes.book}>{t('2 ΝΟΕΜΒΡΙΟΥ 2022')}</p>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={15}>
                            <div className={classes.closed} style={{ float: "right" }}>
                                <p className={classes.bold}>{t('ΚΛΕΙΣΤΟ')}</p>
                            </div>
                        </Col>
                    </Row>
                </div> */}

                <Pagination className={classes.pagination} defaultCurrent={0} onChange={onChange} total={6} />
            </div >
        </>
    );
};

export default Forum