import React, { useState, useEffect } from "react"
import { Row, Col, Select } from 'antd'
import { EnvironmentOutlined, ClockCircleOutlined, SearchOutlined, HeartOutlined, ShakeOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom"
import classes from "./Footer.module.css"
import i18n from "i18next"
import { useTranslation } from "react-i18next";
import logo from "../../img/logo192.png"
import twitter from '../../img/twitter.png'
import facebook from '../../img/facebook.png'
import espa from '../../img/EE_ESPA.png'

const Footer = () => {

    const { t } = useTranslation()

    return (
        <>
            <div className={classes.footerContainer}>
                <Row justify="center">
                    <Col xs={{ span: 22 }} sm={{ span: 20 }} md={{ span: 14 }} lg={{ span: 14 }}>
                        <Row justify="center">
                            <Col xs={24} sm={24} md={8} lg={8} className={classes.links} >
                                <div className={classes.TitleFooter}>{t('Δήμος Σερρών')}</div>
                                <div><span>{t('Ενημέρωση')}</span></div>
                                <div><span>{t('Όροι Χρήσης')}</span></div>
                                <div><span>{t('Πολιτική Απορρήτου')}</span></div>
                                <div><span>{t('Cookies')}</span></div>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} className={classes.links}>
                                <div className={classes.TitleFooter}>{t('Επικοινωνία')}</div>
                                <div className={classes.footerAtag} ><span>{t('Email: ')} <a href="mailto:dserron@serres.gr">dserron@serres.gr</a></span></div>
                                <div className={classes.footerAtag}><span>{t('Τηλέφωνα: ')}
                                    <a href="tel:2321083600">23210 83600</a>,<br /><a href="tel:2321350100">23213 50100</a></span></div>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} className={classes.links}>
                                <div className={classes.TitleFooter}>{t('Ακολουθήστε μας')}</div>
                                <div className={classes.iconsFooter}>
                                    <a href="#"><img src={twitter} className={classes.icon} alt="external-link" /></a>
                                    <a href="#"><img src={facebook} className={classes.icon} alt="external-link" /></a>
                                </div>
                            </Col>
                        </Row>
                        <br /> <br />
                        <img className={classes.espa} src={espa} alt="ee_espa" />
                        <br /> <br />
                        <br /> <br />
                        <div className={classes.iconsFooter} style={{color: '#fff'}}>
                            Powered by <a className={classes.dotsoft} href="https://dotsoft.gr/" target="_blank"><u>Dotsoft</u></a>
                        </div>

                    </Col>
                </Row>
            </div>
        </>
    );
};

export default Footer