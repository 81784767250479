import React, { useState, useEffect } from "react"
import { Row, Col, Select, Breadcrumb, Pagination, Input, Button } from 'antd'
import { RightOutlined, EnvironmentOutlined, ClockCircleOutlined, SearchOutlined, HeartOutlined, ShakeOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { useNavigate, Link, useParams } from "react-router-dom"
import classes from './styles.module.css'
import { useTranslation } from "react-i18next";
import type { PaginationProps, MenuProps } from 'antd'
import parse from 'html-react-parser';
import moment from "moment";
import authService from "../../../services/auth.service";

const { TextArea } = Input;

const Thread = () => {

    const params = useParams();
    const navigate = useNavigate();

    const [topic, setTopic] = React.useState<{ title: string, description: string, id: string, comments: any, created_date: string, end_date: string, is_open: boolean, is_pinned: boolean, is_visible: boolean }>({ id: '', title: '', description: '', comments: [], created_date: '', end_date: '', is_open: false, is_pinned: false, is_visible: false })
    const [current, setCurrent] = useState(0);
    const [comment, setComment] = useState('');
    const [comments, setComments] = useState<[]>([])
    const [value, setValue] = useState('');

    const [errorMessage, setErrorMessage] = React.useState<boolean>(false);

    const addComment = () => {
        if (authService.getCurrentUser())
            postComment()
        else
            setErrorMessage(!errorMessage)
        // setComment()
    }

    const onChange: PaginationProps['onChange'] = (page) => {
        // console.log(page - 1);
        setCurrent(page - 1);
    };
    const { t } = useTranslation()
    const formatDate = (time: any) => {
        require('moment/locale/el');
        let dateFormatted = moment.unix(time).format('LL');
        return dateFormatted;
    }
    const formatTime = (time: any) => {
        require('moment/locale/el');
        let dateFormatted = moment.unix(time).format('LLL');
        return dateFormatted;
    }
    const fetchTopic = () => {
        fetch(`${process.env.REACT_APP_SERVER}/api/el/forum-topics/` + params.id, {
            method: 'GET',
            headers: { "API-KEY": "MXtthZ2ewCmrWQzUj9VPVyOyqqqv6VLe" }
        })
            .then(response => {
                // console.log(response);
                return response.json()
            })
            .then(data => {
                setTopic(data)
                setComments(data.comments);


                console.log(data);
                console.log(comments);
                // var timestamp1 = data.start_date;
                // require('moment/locale/el');
                // dateFormatted1 = moment.unix(timestamp1).format('l');
                // dateFormatted2 = moment.unix(timestamp1).format('hh:mm');
                // SetStartDateTime(dateFormatted1 + " " + dateFormatted2)

                // var timestamp2 = data.end_date;
                // require('moment/locale/el');
                // dateFormatted3 = moment.unix(timestamp2).format('l');
                // dateFormatted4 = moment.unix(timestamp2).format('hh:mm');
                // SetEndDateTime(dateFormatted3 + " " + dateFormatted4)
            })
    }
    const postComment = () => {
        fetch(`${process.env.REACT_APP_SERVER}/api/el/forum-topics/` + params.id + '/comments', {
            method: 'POST',
            headers: new Headers({
                'Accept': 'application/json',
                'Origin': '',
                "Authorization": `Bearer ${window.localStorage.getItem("access_token")}`,
                'Content-Type': 'application/json',

            }),
            body: JSON.stringify({
                'description': value
            })
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                // setTopic(data)
                console.log(data);

                // var timestamp1 = data.start_date;
                // require('moment/locale/el');
                // dateFormatted1 = moment.unix(timestamp1).format('l');
                // dateFormatted2 = moment.unix(timestamp1).format('hh:mm');
                // SetStartDateTime(dateFormatted1 + " " + dateFormatted2)

                // var timestamp2 = data.end_date;
                // require('moment/locale/el');
                // dateFormatted3 = moment.unix(timestamp2).format('l');
                // dateFormatted4 = moment.unix(timestamp2).format('hh:mm');
                // SetEndDateTime(dateFormatted3 + " " + dateFormatted4)
            })
    }

    useEffect(() => {
        fetchTopic()
        console.log(comments);
    }, [])

    return (
        <>
            <div className={classes.container}>
                <Row justify="center">
                    <Col lg={24}>
                        <div className={classes.title}>
                            {t('ΔΗΜΟΣΙΟ FORUM')}
                        </div>
                    </Col>
                </Row>
            </div>
            <div className={classes.breadcrumbContainer}>
                <Row justify="center">
                    <Col xs={24} sm={24} md={15} lg={15}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <a href="/">ΑΡΧΙΚΗ ΣΕΛΙΔΑ</a>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <a href="/forum">{t('FORUM')}</a>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <p lang="el" style={{ textTransform: "uppercase" }}>{topic.title}</p>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </div>
            <div className={classes.mainContainer}>
                <div className={classes.wrap}>
                    <Row>
                        <Row style={{ width: '100%' }}>
                            <Col xs={21} sm={21} md={21} lg={21}>
                                <div className={classes.title}>
                                    {t(topic.title)}
                                </div>

                            </Col>
                            <Col xs={3} sm={3} md={3} lg={3}>
                                {topic.is_open ?
                                    <UnlockOutlined className={classes.iconLock} style={{ backgroundColor: "#88c16d" }} /> :
                                    <LockOutlined className={classes.iconLock} style={{ backgroundColor: "#f63636" }} />
                                }
                            </Col>
                        </Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <div className={classes.subTitle}>
                                {parse(`${topic.description}`)}
                            </div>
                        </Col>
                        {/* <Col xs={24} sm={24} md={24} lg={2}>
                            <div className={classes.param}>
                                <p className={classes.bold}>{t(topic.comments.length)} </p><p className={classes.book}>{t('ΣΧΟΛΙΑ')}</p>
                            </div>
                        </Col> */}
                        <Col xs={24} sm={24} md={7} lg={7}>
                            <div className={classes.param}>
                                <p className={classes.bold}>{t('ΗΜ. ΚΑΤΑΧΩΡΗΣΗΣ')}: </p><p lang="el" className={classes.book}>{formatDate(topic.created_date)}</p>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={17} lg={17}>
                            {topic.is_open ?
                                <div className={classes.paramEnd} style={{ float: "right" }}>
                                    <p className={classes.bold}>{t('ΑΝΟΙΚΤΟ ΕΩΣ')}: </p><p lang="el" className={classes.book}>{formatDate(topic.end_date)}</p>
                                </div> :
                                <div className={classes.closed} style={{ float: "right" }}>
                                    <p className={classes.bold}>{t('ΚΛΕΙΣΤΟ')}</p>
                                </div>

                            }

                        </Col>
                    </Row>
                </div>
                <div className={classes.commentsWrap}>
                    <Col xs={24} sm={24} md={24} lg={3}>
                        <div className={classes.comments}>
                            <p className={classes.bold}>{topic.comments.length} </p><p className={classes.book}>{t('ΣΧΟΛΙΑ')}</p>
                        </div>
                    </Col>
                </div>

                {comments &&
                    comments.map((comment: any) => {
                        return (
                            <div className={classes.wrapUser}>
                                <Row>
                                    <Col xs={24} sm={24} md={7} lg={7}>
                                        <div className={classes.param} style={{ float: "left", marginBottom: 31, marginTop: 11 }}>
                                            <p className={classes.bold}>{t('ΑΠΟ')}: </p><p className={classes.book}>{t(comment.author)}</p>
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={24} md={17} lg={17}>
                                        <div className={classes.paramEnd} style={{ float: "right", marginBottom: 31, marginTop: 11 }}>
                                            <p className={classes.bold}>{t('ΗΜ')}: </p><p lang="el" className={classes.book}>{t(formatTime(comment.created_date))}</p>
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24}>
                                        <div className={classes.subTitle} style={{ maxWidth: '1138' }}>
                                            {t(comment.description)}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        )
                    })
                }



                {/* <div className={classes.wrapUser}>
                    <Row>
                        <Col xs={24} sm={24} md={7} lg={7}>
                            <div className={classes.param} style={{ float: "left", marginBottom: 31, marginTop: 11 }}>
                                <p className={classes.bold}>{t('ΑΠΟ')}: </p><p className={classes.book}>{t('ADMIN')}</p>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={17} lg={17}>
                            <div className={classes.paramEnd} style={{ float: "right", marginBottom: 31, marginTop: 11 }}>
                                <p className={classes.bold}>{t('ΗΜ')}: </p><p className={classes.book}>{t('30 ΔΕΚΕΜΒΡΙΟΥ 2022 - 19:52')}</p>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <div className={classes.subTitle} style={{ maxWidth: '1138' }}>
                                {t(`Ab vitae adipisci eos quia inventore quo sapiente fuga aut quia unde eos commodi sapiente 33 magnam dolores et molestiae iste. In deleniti quod a provident consequuntur ut dolor corrupti.

`)}
                            </div>
                        </Col>


                    </Row>
                </div>
                <div className={classes.wrapUser}>
                    <Row>
                        <Col xs={24} sm={24} md={7} lg={7}>
                            <div className={classes.param} style={{ float: "left", marginBottom: 31, marginTop: 11 }}>
                                <p className={classes.bold}>{t('ΑΠΟ')}: </p><p className={classes.book}>{t('ADMIN')}</p>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={17} lg={17}>
                            <div className={classes.paramEnd} style={{ float: "right", marginBottom: 31, marginTop: 11 }}>
                                <p className={classes.bold}>{t('ΗΜ')}: </p><p className={classes.book}>{t('30 ΔΕΚΕΜΒΡΙΟΥ 2022 - 19:52')}</p>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <div className={classes.subTitle} style={{ maxWidth: '1138' }}>
                                {t(`Ab vitae adipisci eos quia inventore quo sapiente fuga aut quia unde eos commodi sapiente 33 magnam dolores et molestiae iste. In deleniti quod a provident consequuntur ut dolor corrupti.

`)}
                            </div>
                        </Col>


                    </Row>
                </div> */}

                {/* <Pagination className={classes.pagination} defaultCurrent={0} onChange={onChange} total={topics.comments.length * 2.5} /> */}

                <div className={classes.wrap}>
                    <Row>
                        <Row style={{ width: '100%' }}>
                            <Col xs={24} sm={24} md={24} lg={23}>
                                <div className={classes.titleComment}>
                                    {t(`ΚΑΤΑΧΩΡΗΣΗ ΣΧΟΛΙΟΥ*`)}
                                </div>

                            </Col>
                        </Row>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <TextArea
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                placeholder=""
                                autoSize={{ minRows: 8, maxRows: 18 }}
                                className={classes.textfield}
                            />
                        </Col>

                        <Button

                            size='large'
                            className={classes.DeclareBtn}
                            onClick={addComment}
                        >
                            {t('ΥΠΟΒΟΛΗ')}<RightOutlined />
                        </Button>

                    </Row>
                    <div style={{ textAlign: "center", padding: 20 }}>
                        {!errorMessage ? <div className={classes.error}> {"*Πρέπει να είστε εγγεγραμμένος χρήστης για να μπορέσετε να υποβάλλετε σχόλιο!"} </div> : <div className={classes.errorRed}> {"*Πρέπει να είστε εγγεγραμμένος χρήστης για να μπορέσετε να υποβάλλετε σχόλιο!"} </div>}

                    </div>
                </div>



            </div >

        </>
    );
};

export default Thread