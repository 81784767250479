import React, { useState, useEffect, } from "react"
import { Row, Col, Select, Breadcrumb, Button } from 'antd'
import { EnvironmentOutlined, ClockCircleOutlined, SearchOutlined, HeartOutlined, ShakeOutlined } from '@ant-design/icons';
import { RightOutlined } from '@ant-design/icons'
import { Link, useParams, } from "react-router-dom"
import classes from './styles.module.css'
import { useTranslation } from "react-i18next";
import parse from 'html-react-parser';
import moment from "moment";
import Modal from '../../components/Modal/Modal'
import "moment-timezone";
import "moment/locale/el";

import Posts from "../Home/components/Posts"
import { log } from "console";

export type NewsPost = {
    id: string;
    title: string;
    // image: image;
    url: string;
}

// export type image = {
//     description: string;
//     url: string;
// }

export type PostTypes = NewsPost[];

const ViewAction = () => {

    const { t } = useTranslation()

    const params = useParams();

    const year = new Date();

    const timeZone = 'Europe/Athens';

    const [action, setAction] = useState<{ id: '', title: "", image: { url: "" }, category: { title: "" }, address: '', location: '', organizer: '', description: '' }>();

    const [title, setTitle] = useState<string>("")
    const [startDateTime, SetStartDateTime] = useState<string>("");
    const [endDateTime, SetEndDateTime] = useState<string>("");
    let dateFormatted1, dateFormatted2, dateFormatted3, dateFormatted4 = "";

    const fetchNewsData = () => {
        // console.log(params);
        fetch(`${process.env.REACT_APP_SERVER}/api/el/volunteering-actions/` + params.id, {
            method: 'GET',
            headers: { "API-KEY": "MXtthZ2ewCmrWQzUj9VPVyOyqqqv6VLe" }
        })
            .then(response => {
                // console.log(response);
                return response.json()
            })
            .then(data => {
                setAction(data);
                console.log(data);

                setTitle(data.title);
                const startDateTime = moment.unix(data.start_date).tz(timeZone);
                const formattedStartDateTime = startDateTime.locale("el").format("DD/MM/YYYY HH:mm");
                SetStartDateTime(formattedStartDateTime);

                console.log(data.end_date);

                const endDateTime = moment.unix(data.end_date).tz(timeZone);
                const formattedEndDateTime = endDateTime.locale("el").format("DD/MM/YYYY HH:mm");
                SetEndDateTime(formattedEndDateTime);
            });
    }


    useEffect(() => {
        fetchNewsData();
        console.log(window.localStorage.getItem("userData"));


    }, [])

    return (
        <>
            <div className={classes.container}>
                <Row justify="center">
                    <Col lg={24}>
                        <div className={classes.title}>
                            {t('ΔΡΑΣΕΙΣ')}
                        </div>
                    </Col>
                </Row>
            </div>

            <div className={classes.breadcrumbContainer}>
                <Row justify="center">
                    <Col xs={24} sm={24} md={15} lg={15}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <a href="/">{t('ΑΡΧΙΚΗ ΣΕΛΙΔΑ')}</a>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <a href="/actions">{t('ΔΡΑΣΕΙΣ')}</a>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <p style={{ textTransform: "uppercase" }}>{action?.title}</p>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </div>
            <div className={classes.mainContainer}>

                <Row>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <div className={classes.Category}>
                            {/* {t('ΚΑΤΗΓΟΡΙΑ ΔΡΑΣΗΣ')} */}
                            <p lang='el' style={{ textTransform: "uppercase" }}>{action?.category.title}</p>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <div className="">
                            <div className={classes.ActionTitle}>
                                {action?.title}
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                        <div className={classes.Details}>
                            <strong>{t('ΔΙΑΡΚΕΙΑ ')}</strong>{t('απο: ')}<strong><p lang='el' style={{ textTransform: "uppercase" }}>{startDateTime}</p></strong>{t('  εωσ: ')}
                            <strong>{endDateTime}</strong>

                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={10}>
                        <div className={classes.Details}>
                            <strong>{t('Διευθυνση: ')}</strong>
                            <p lang='el' style={{ textTransform: "uppercase" }}>{action?.address}</p>
                        </div>
                    </Col>
                </Row>
                <img className={classes.ImagePost} src={action?.image.url} alt="Image" />
            </div>

            <div className={classes.mainContainer}>

                <Row>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <div className={classes.Category}>
                            {t('ΠΕΡΙΓΡΑΦΗ')}
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <div className={classes.Description}>
                            {parse(`${action?.description}`)}
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <div className={classes.Category}>
                            {t('ΔΙΟΡΓΑΝΩΤΗΣ')}
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <div className={classes.Organizer}>
                            <strong>{action?.organizer}</strong>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <div className={classes.Category}>
                            {t('ΠΕΡΙΟΧΗ ΔΡΑΣΗΣ')}
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <div className={classes.Description}>
                            {action?.location}
                        </div>
                    </Col>
                </Row>
                <div className={classes.PostBtn}><Modal id={+params.id!} title={title} start_date={startDateTime} /></div>
            </div>
        </>
    );
};

export default ViewAction