import React, { useState, useEffect } from "react"
import classes from "./css/Home.module.css"
import { useTranslation } from "react-i18next"
import { Row, Col, Button } from 'antd'
import { useNavigate, Link } from "react-router-dom"
import { RightOutlined } from '@ant-design/icons'
import HelmetComponent from '../../components/Helmet/Helmet'
import Info from './components/Info'
import parse from 'html-react-parser';
import moment from "moment";
import Slider from './components/Slider'
import authService from "../../services/auth.service"
import Modal from "../../components/Modal/Modal"

const Home = () => {
    let url = `${process.env.REACT_APP_SERVER}/api/el/news`;
    let page = "";
    const newsUrl = `${process.env.REACT_APP_SERVER}/api/el/news`;
    const actionsUrl = `${process.env.REACT_APP_SERVER}/api/el/volunteering-actions`;

    const { t } = useTranslation()

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

    const [news, setNews] = React.useState<any[]>([])
    const [actions, setActions] = React.useState<any[]>([])
    const [current, setCurrent] = useState(0);

    const fetchData = async () => {
        fetch(newsUrl, {
            method: 'GET',
            mode: 'cors',
            headers: { "API-KEY": "MXtthZ2ewCmrWQzUj9VPVyOyqqqv6VLe" }
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                // console.log(data.news);

                const dataa = data.news.map((row: any) => {
                    const create = authService.formatTimeQuick(row.created);
                    row.createDate = create;
                    return row
                })
                dataa.sort((a: any, b: any) => (authService.checkNewest(a.createDate, b.createDate)) ? 1 : -1)
                setNews(dataa);
            })

        fetch(actionsUrl, {
            method: 'GET',
            mode: 'cors',
            headers: { "API-KEY": "MXtthZ2ewCmrWQzUj9VPVyOyqqqv6VLe" }
        })
            .then(response => {
                return response.json()
            })
            .then(data => {
                const dataa = data.volunteering_actions.map((row: any) => {
                    const start = authService.formatTimeQuick(row.start_date);
                    row.startDate = start;
                    const end = authService.formatTimeQuick(row.end_date);
                    row.endDate = end;
                    const period = row.startDate + " - " + row.endDate;
                    row.period = period;
                    return row
                })
                dataa.sort((a: any, b: any) => (authService.checkNewest(a.endDate, b.endDate)) ? 1 : -1)
                setActions(dataa);
            })
    };

    const formatTime = (time: any) => {
        require('moment/locale/el');
        let dateFormatted = moment.unix(time).format('LL');
        return dateFormatted;
    }
    useEffect(() => {
        // console.log(localStorage.getItem("userData"));
        // console.log(authService.getCurrentUser());
        // console.log(authService.getUserProfile());


        fetchData();
    }, [])
    return (
        <>
            <HelmetComponent title={t('Δίκτυο Εθελοντισμού') || undefined} descrition={t('Δίκτυο Εθελοντισμού') || undefined} />
            <Slider />
            <div className={classes.container}>
                <Row>
                    {/* Actions row */}
                    <Col lg={12} md={12} sm={24} xs={24}>
                        <div className={classes.TitlePosts} style={{ borderRight: '1px dashed #2a576e' }}>
                            {t('ΔΡΑΣΕΙΣ')}
                        </div>
                        {actions &&
                            actions.slice(current * 4, 4 * current + 2).map((data) => {
                                return (
                                    // <Post />
                                    <Col lg={12} className={classes.dashed} style={{ borderRight: '1px dashed #2a576e' }}>
                                        <div key={data.id} className={classes.Post}>
                                            <img className={classes.ImagePost} src={data.image.url} alt={data.image.description} />
                                            <div className={classes.Date}>
                                                <p lang='el' style={{ textTransform: "uppercase" }}>{formatTime(data.start_date)}</p>
                                            </div>
                                            <div className={classes.TitlePost}>
                                                {data.title}
                                            </div>
                                            <div className={classes.ExcerptPost}>
                                                {parse(`${data.description}`)}
                                            </div>
                                            <Link to={`../actions/action/${data.id}`}>
                                                <Button
                                                    size='large'
                                                    className={classes.PostBtn}
                                                // onClick={(getMoviesFromApiAsync) => navigate("/news-post")}
                                                >
                                                    {t('ΠΕΡΙΣΣΟΤΕΡΑ')}<RightOutlined />
                                                </Button>
                                            </Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                            <Modal id={data.id} title={data.title} start_date={data.start_date}/>
                                            {/* <Button
                                            size='large'
                                            className={classes.PostBtn}
                                            onClick={(getMoviesFromApiAsync) => navigate("/news-post")}
                                        >
                                            {t('ΠΕΡΙΣΣΟΤΕΡΑ')}<RightOutlined />
                                        </Button> */}
                                        </div>

                                    </Col>
                                )

                            })}
                    </Col>
                    {/* News row */}
                    <Col lg={12} md={12} sm={24} xs={24}>
                        <div className={classes.TitlePosts} >
                            {t('ΝΕΑ')}
                        </div>
                        {/* <ViewNewsPost />
                        <Post /> */}
                        {news &&
                            news.slice(current * 4, 4 * current + 4).map((data) => {
                                return (
                                    // <Post />
                                    <Col lg={12} >
                                        <div key={data.id} className={classes.Post}>
                                            <img className={classes.ImagePost} src={data.image.url} alt={data.image.description} />
                                            <div className={classes.Date}>
                                                {/* {data.created} */}

                                                <p lang='el' style={{ textTransform: "uppercase" }}>{formatTime(data.created)}</p>


                                            </div>
                                            <div className={classes.TitlePost}>
                                                {data.title}
                                                {/* {t('Χρυσάφης: 26 + 2 νέες παιδικές χαρές το 2022 στον Δήμο Σερρών')} */}
                                            </div>
                                            <div className={classes.ExcerptPost}>
                                                {parse(`${data.description}`)}
                                                {/* {t('Στην κατασκευή 26 νέων παιδικών χαρών στην πόλη και στα χωριά, καθώς και στην αναβάθμιση των παιδικών χαρών στο ΙΚΑ και στον Αγ. Δημήτριο προχωρά το 2022 ο Δήμος Σερρών. Σε αυτές προστίθενται και οι δύο παιδικές χαρές...')} */}
                                            </div>
                                            <Link to={`../news/post/${data.id}`}>
                                                <Button
                                                    size='large'
                                                    className={classes.PostBtn}
                                                // onClick={(getMoviesFromApiAsync) => navigate("/news-post")}
                                                >
                                                    {t('ΠΕΡΙΣΣΟΤΕΡΑ')}<RightOutlined />
                                                </Button>
                                            </Link>
                                            {/* <Button
                                            size='large'
                                            className={classes.PostBtn}
                                            onClick={(getMoviesFromApiAsync) => navigate("/news-post")}
                                        >
                                            {t('ΠΕΡΙΣΣΟΤΕΡΑ')}<RightOutlined />
                                        </Button> */}
                                        </div>

                                    </Col>
                                )

                            })}
                    </Col>
                </Row>
            </div>
            <Info />
        </>
    )
}

export default Home