import React from 'react'
import { Row, Col, Carousel, Button } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import classes from "./css/Slider.module.css"
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'

const Slider: React.FC = () => {
    const [data, setData] = React.useState<any[]>([])

    const { t } = useTranslation()

    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        autoplaySpeed: 8000,
        speed: 2500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1056,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 680,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }
    return (
        <>
            <div className={classes.container}>
                <div className={classes.sliderContainer}>
                    <Carousel autoplay draggable pauseOnDotsHover pauseOnHover {...settings} >
                        <div className={[classes.image, classes.slider1].join(" ")} >
                            <Row>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <div className={classes.title}>
                                        {t('ΕΘΕΛΟΝΤΙΚΗ ΑΙΜΟΔΟΣΙΑ')}
                                    </div>
                                    <div className={classes.subTitle}>
                                        {t(`O Δήμος Σερρών, σε συνεργασία με το Γενικό Νοσοκομείο Σερρών, διοργανώνει εθελοντική
αιμοδοσία στον ειδικά διαμορφωμένο χώρο του ΔΗ.ΠΕ.ΘΕ. Σερρών`)}
                                    </div>
                                    <Link to={`../actions/action/3`}>
                                        <Button
                                            size='large'
                                            className={classes.Btn}
                                        >
                                            {t('ΠΕΡΙΣΣΟΤΕΡΑ')}&nbsp;&nbsp;<RightOutlined />
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                        <div className={[classes.image, classes.slider2].join(" ")} >
                            <Row>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <div className={classes.title}>
                                        {t(`ΔΙΑΝΟΜΗ ΣΥΣΣΙΤΙΟΥ ΚΑΤ' ΟΙΚΟΝ`)}
                                    </div>
                                    <div className={classes.subTitle}>
                                        {t(`Η Δομή Παροχής Συσσιτίου οργανώνει εβδομαδιαία εθελοντική δράση διανομής συσσιτίου.
Στόχος της συγκεκριμένης πράξης είναι η παροχή βασικών αγαθών, σε εκείνο το τμήμα του πληθυσμού που δεν μπορεί να καλύψει τις ανάγκες του.`)}
                                    </div>
                                    <Link to={`../actions/action/4`}>
                                        <Button
                                            size='large'
                                            className={classes.Btn}
                                        >
                                            {t('ΠΕΡΙΣΣΟΤΕΡΑ')}&nbsp;&nbsp;<RightOutlined />
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                        <div className={[classes.image, classes.slider3].join(" ")} >
                            <Row>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <div className={classes.title}>
                                        {t('ΠΕΡΙΒΑΛΛΟΝΤΙΚΗ ΔΡΑΣΗ ΕΘΕΛΟΝΤΩΝ')}
                                    </div>
                                    <div className={classes.subTitle}>
                                        {t(`Ο Δήμος Σερρών διοργανώνει περιβαλλοντική δράση με τη συμμετοχή εθελοντών αλλά και
εθελοντών που ανήκουν στην κατηγορία των ευπαθών ομάδων (όπως ΑΜΕΑ/παιδιά
καρκινοπαθή κτλ.`)}
                                    </div>
                                    <Link to={`../actions/action/5`}>
                                        <Button
                                            size='large'
                                            className={classes.Btn}
                                        >
                                            {t('ΠΕΡΙΣΣΟΤΕΡΑ')}&nbsp;&nbsp;<RightOutlined />
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                        
                        <div className={[classes.image, classes.slider4].join(" ")} >
                            <Row>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <div className={classes.title}>
                                        {t('Εθελοντική δράση άθλησης για ευπαθείς ομάδες')}
                                    </div>
                                    <div className={classes.subTitle}>
                                        {t(`Ο Δήμος Σερρών σε συνέχεια της προσπάθειας άσκησης μιας αποτελεσματικής κοινωνικής πολιτικής για έναν αλληλέγγυο δήμο διοργανώνει νέα εθελοντική δράση εστιάζοντας στην προαγωγή της υγείας σε ευπαθείς ομάδες μέσω της άσκησης.`)}
                                    </div>
                                    <Link to={`../actions/action/9`}>
                                        <Button
                                            size='large'
                                            className={classes.Btn}
                                        >
                                            {t('ΠΕΡΙΣΣΟΤΕΡΑ')}&nbsp;&nbsp;<RightOutlined />
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                        
                    </Carousel>
                </div>
            </div>
        </>
    )
}

export default Slider
