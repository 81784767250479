import { useState } from "react";
import { EditOutlined, SaveOutlined } from '@ant-design/icons'
import classes from './styles.module.css'
import EditIcon from './unlock-white.png'
import { Row, Col } from 'antd'

type Props = {
  defaultValue: string;
  placeholder: string;
  setVal: any;
};

export const InfoInput: React.FC<Props> = ({ defaultValue, placeholder, setVal }) => {

  const [value, setValue] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [clicked, setClicked] = useState(false);

  !clicked ? setVal(defaultValue) : setVal(value)


  const handleEditClick = () => {
    setClicked(true)
    setVal(value);
    setDisabled(false);
  };

  const handleSaveClick = () => {
    setClicked(true)

    setDisabled(true);
    // save logic goes here
  };

  //   const onChange = (selected) => {
  //     this.setState({ selected });
  //     props.onSelectedOptionsChange(selected);
  // };

  return (
    <Row>
      <label className={classes.label}>

        <input
          type="text"
          className={classes.Data}
          onChange={(e) =>
            setValue(e.target.value)
          }
          value={value}
          placeholder={placeholder}
        />
        {disabled && (
          <button
            type="button"
            onClick={handleEditClick}
            className={classes.edit}
          >
            <img src={EditIcon} alt="edit" />
            {/* <EditOutlined /> */}
          </button>
        )}

        {!disabled && (
          <button
            type="button"
            onClick={handleSaveClick}
            className={classes.edit}

          >
            <Col>
              <img src={EditIcon} alt="edit" />
            </Col>
          </button>
        )}

      </label>
    </Row>
  );
};
