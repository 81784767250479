import React, { useState, useEffect } from "react"
import { Row, Col, Select } from 'antd'
import { EnvironmentOutlined, ClockCircleOutlined, SearchOutlined, HeartOutlined, UserOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom"
import classes from "./Header.module.css"
import i18n from "i18next"
import { useTranslation } from "react-i18next";
import logo from "../../img/logo192.png"
import Navbar from './Navbar'
import { Button } from 'antd';

const Header = () => {

    const { t } = useTranslation()

    return (
        <>
            <div className={[classes.header, classes.flexContainer].join(" ")}>

                <div className={classes.containerBottom}>

                    <Navbar />

                    <div className={classes.Rectangle1}>
                        <Row justify="center">
                            <Col lg={13} md={13} sm={13} xs={13} style={{ borderRight: '1px dashed white', textAlign: 'right' }}>
                                <div className={classes.TitleHeader}>
                                    <div style={{fontWeight: 500}}>{t('ΔΙΚΤΥΟ')}<br/></div>
                                    {t('ΕΘΕΛΟΝΤΙΣΜΟΥ')}
                                </div>
                                <div className={classes.TextHeader}>
                                    {t('Ανάπτυξη και λειτουργία δικτύου εθελοντισμού υποστήριξης των ευπαθών ομάδων πληθυσμού')}
                                </div>
                            </Col>
                            <Col lg={11} md={11} sm={11} xs={11}>
                                <div className={classes.app_logo}>
                                <Link to="/" state={{ page: 'home' }} ><img src="../../img/logo-white.png" alt="logo" /></Link>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    
                </div>
            </div>
        </>
    );
};

export default Header